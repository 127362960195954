import styled from "styled-components"
import BrandLogo from "../brand-logo"
import { ApplicationRole } from "../../models/authentication/application-roles"
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { logoutUser } from "../../store/features/authentication/user-slice";
import Button from "../controls/button";
import { useEffect, useRef, useState } from "react";
import { GoogleIcon, ICON_ID, ICON_SIZES } from "../icons/google-icon";
import { useChangeTheme } from "../../hooks/useChangeTheme";
import { TopNavContainer } from "../../styles/shared/page";
import { ApplicationBasket } from "../../models/order/basket/application-basket";
import BasketIcon from "../icons/basket-icon";
import { useNavigate } from "react-router-dom";
import { ApplicationBranch } from "../../models/organisation/application-branch";
import Dropdown from "../controls/dropdown";
import { SelectChangeEvent } from "@mui/material";

interface HeaderProps {
  name: string | undefined;
  team: ApplicationRole | undefined;
  basket?: ApplicationBasket | null
  branches?: ApplicationBranch[] | undefined
  selectedBranch?: ApplicationBranch | null
  onBranchChange?: (branch: ApplicationBranch) => void
}

export default function Header({ onBranchChange, selectedBranch, name, team, basket, branches }: HeaderProps) {
  const dispatch = useAppDispatch();
  const [displayUserActions, setDisplayUserActions] = useState(false);
  const userContainerRef = useRef<HTMLDivElement>(null);
  const changeTheme = useChangeTheme();
  const navigate = useNavigate();
  const handleLogout = async () => {
    dispatch(logoutUser());
  }

  const handleUserContainerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDisplayUserActions(!displayUserActions);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (userContainerRef.current && !userContainerRef.current.contains(event.target as Node)) {
        setDisplayUserActions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBasketClick = () => {
    navigate("/basket");
  }

  const onDropdownBranchChange = (event: SelectChangeEvent<unknown>) => {
    var branch = branches?.find(x => x.id == event.target.value);
    if (branch && onBranchChange) {
      onBranchChange(branch);
    }
  }

  const branchOptions = branches?.map(branch => ({ value: branch.id.toString(), label: branch.name }));

  return (
    <TopNavContainer>
      <BrandContainer onClick={() => navigate("/")}>
        <BrandLogo size="medium"></BrandLogo>
      </BrandContainer>

      {selectedBranch && (
        <BranchContainer>
          {(branchOptions != null && branchOptions.length > 1 && onBranchChange) ?
            <Dropdown id="cboBranch" variant="outlined" autoWidth={false} label="Branch" onChange={onDropdownBranchChange} value={selectedBranch?.id} options={branchOptions} multiple={false} native={false}></Dropdown>
            : <BranchDisplay>
              <GoogleIcon id={ICON_ID.Domain} size={ICON_SIZES.Medium} isNeutral={true} ></GoogleIcon>
              {selectedBranch?.name} Branch
            </BranchDisplay>
          }
        </BranchContainer>
      )}
      {
        basket != null && (
          <BasketContainer>
            <BasketIcon count={basket?.itemCount ?? 0} onClick={handleBasketClick} ></BasketIcon>
          </BasketContainer>
        )
      }
      <AccountInformationContainer>
        <UserContainer ref={userContainerRef} onClick={handleUserContainerClick}>
          <GoogleIcon size={ICON_SIZES.Medium} id={ICON_ID.AccountCircle}></GoogleIcon>
          {name}

          {displayUserActions && (
            <UserActions>
              <ListItem onClick={changeTheme}><GoogleIcon id={ICON_ID.SwapHorizontal} /> Change Theme</ListItem>
              <ListItem onClick={handleLogout}><GoogleIcon id={ICON_ID.Logout} /> Logout</ListItem>
            </UserActions>
          )}
        </UserContainer>
        <TeamContainer>
          <GoogleIcon id={ICON_ID.SwapHorizontal}></GoogleIcon> {team}
        </TeamContainer>
      </AccountInformationContainer>
    </TopNavContainer >
  )
}

const BrandContainer = styled.div`
 flex: 1;
 padding: 4px 28px;
 cursor:pointer;

 img {
    background: ${props => props.theme.application.logoBackground}; 
    border-radius:8px;
    box-shadow: ${props => props.theme.application.boxShadow}; 
    padding:10px;
 }
`

const BranchContainer = styled.div`
 align-self: center;
 margin-right:26px;
 flex-grow:0.1;
`
const BranchDisplay = styled.span`
  font-size:1rem;    
  font-weight: 600;

  i{
    vertical-align: top;
  }
`
const BasketContainer = styled.div`
    align-self: center;
    margin-right:26px;
`
const AccountInformationContainer = styled.div`
    align-content: center;
    margin: 0 30px;
`

const UserContainer = styled.div`
    position:relative;
    font-size:1.2rem;
    margin-bottom:5px;
    cursor:pointer;    
    font-weight:600;
`

const TeamContainer = styled.div`
font-size:1rem;
`

const UserActions = styled.div`
    font-weight:400;
    font-size:1rem;
    position:absolute;    
    box-shadow: ${props => props.theme.application.boxShadow}; 
    left:0;
    background: ${props => props.theme.application.navbar.background};
`

const ListItem = styled.div` 
cursor:pointer;
padding:5px;
border-bottom: 1px solid ${props => props.theme.application.border};    
color: ${props => props.theme.application.link};


&:hover {
    background: ${props => props.theme.application.hover};
}

`
