import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/controls/button';
import { ApplicationProductDetail } from '../../models/product/application-product-detail';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchByProductId, insertBasketItem } from '../../store/features/ordering/basket/basket-slice';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ApplicationBasketItem } from '../../models/order/basket/application-basket-item';
import { minValueValidator } from '../../helpers/validators';
import { showSuccess } from '../../components/notification/toastr-actions';
import Image from '../../components/controls/image';
import TextInput from '../../components/controls/text-input';
import { fetchProduct, fetchSupplierProduct } from '../../store/features/product/product-slice';
import { ApplicationApprovalStatus } from '../../models/order/application-approval-status';
import { FeatureCard, FeatureCardColumn, FeatureCardRow, FeatureCardSingleColumn, FeatureCardTitle } from '../../styles/shared/card';
import useEnterKeyListener from '../../hooks/useEnterKeyListener';
import { ApplicationBestSupplierProduct } from '../../models/product/application-best-supplier-product';
import { SmallTextContainer, TextContainer } from '../../styles/shared/table';
import { currencyFormatter } from '../../helpers/formatters';
import { MediumSpacer, SmallSpacer } from '../../styles/shared/spacing';

interface ProductModalProps {
    productId: number,
    basketCheck: boolean,
    addToBasketCallback?: () => void,
}

interface ErrorState {
    quantity: string | null;
    currentStock: string | null;
}

export default function ProductModal({ productId, basketCheck, addToBasketCallback }: ProductModalProps) {
    const [currentStock, setCurrentStock] = useState<number | null>();
    const [quantity, setQuantity] = useState<number>(0);
    const [notes, setNotes] = useState<string>("");
    const [addToBasketMessage, setAddToBasketMessage] = useState<string>("Add to basket");
    const [errors, setErrors] = useState<ErrorState>({
        quantity: null,
        currentStock: null
    });
    const [inBasket, setInBasket] = useState<boolean>(false);
    const basket = useAppSelector(state => state.basket);
    const dispatch = useAppDispatch();
    const [product, setProduct] = useState<ApplicationProductDetail>();
    const [supplierProduct, setSupplierProduct] = useState<ApplicationBestSupplierProduct>();

    useEffect(() => {
        if (productId) {
            dispatch(fetchProduct(productId)).then((response) => {
                var product = response.payload as ApplicationProductDetail;
                setProduct(product)
                setCurrentStock(null);
                setQuantity(product.suggestedQuantity == 0 ? 1 : product.suggestedQuantity);
            })


            dispatch(fetchSupplierProduct(productId)).then((response) => {
                var supplierProduct = response.payload as ApplicationBestSupplierProduct
                if (supplierProduct.supplierId > 0) {
                    setSupplierProduct(supplierProduct);
                }
            })
        }
    }, [productId])


    useEffect(() => {
        if (basket.basket && product && basketCheck) {
            dispatch(fetchByProductId({ basketId: basket.basket.id, productId: product.id }))
                .then((response) => {
                    var basketItem = response.payload as ApplicationBasketItem;
                    if (basketItem.id > 0) {
                        setInBasket(true);
                        setQuantity(basketItem.quantity);
                        setCurrentStock(basketItem.currentStock);
                        setNotes(basketItem.notes);
                        setAddToBasketMessage("Added to basket");
                    }
                })
        }
    }, [basket.basket, product])

    const addToBasket = () => {
        const quantityError = minValueValidator(quantity);
        const currentStockError = minValueValidator(currentStock, 0);

        if (quantityError || currentStockError || !product) {
            setErrors({ ...errors, quantity: quantityError, currentStock: currentStockError });
        } else {
            var basketItem: ApplicationBasketItem = {
                id: 0,
                productId: product.id,
                basketId: basket.basket!.id,
                productName: product.name,
                productDescription: product.description,
                productImage: product.image,
                productSize: product.size,
                plu: product.plu,
                quantity: quantity,
                notes: notes,
                currentStock: currentStock!,
                approvalStatus: ApplicationApprovalStatus.Approved,
            }

            dispatch(insertBasketItem({ basketId: basket.basket!.id, basketItem: basketItem }))
            dispatch(showSuccess(`${product.name} added to basket`));

            if (addToBasketCallback) {
                addToBasketCallback();
            }
        }
    }

    useEnterKeyListener(addToBasket);

    const handleQuantityChange = (value: number | null) => {
        var input = value ?? 0;
        setQuantity(input);

        setErrors({ ...errors, quantity: minValueValidator(input, 0) })
    }


    const handleStockLevelChange = (value: number) => {
        if (isNaN(value)) {
            setCurrentStock(undefined);
        } else {
            setCurrentStock(Math.floor(value));
        }
        setErrors({ ...errors, currentStock: minValueValidator(value,) })
    }

    if (!product) {
        return (<Container>
            No Product
        </Container>);
    }

    return (
        <Container>
            <ProductName id={`product_${product.id}`}> {product.name} </ProductName>
            <ProductImage>
                <Image image={product.image}></Image>
            </ProductImage>
            <ProductDescription>{product.description}</ProductDescription>
            <ProductDetails>
                <ProductDetail>
                    <span>Size:</span>
                    <ProductDetailValue>{product.size}</ProductDetailValue>
                </ProductDetail>
                <ProductDetail>
                    <span>Plu:</span>
                    <ProductDetailValue>{product.plu}</ProductDetailValue>
                </ProductDetail>

                {Object.entries(product.information).map(([key, value]) => {
                    return (
                        <ProductDetail key={key}>
                            <span>{key}:</span>
                            <ProductDetailValue>{value}</ProductDetailValue>
                        </ProductDetail>
                    )
                })}
            </ProductDetails>

            {supplierProduct && (
                <FeatureCard>
                    <FeatureCardTitle>
                        Supplier
                    </FeatureCardTitle>
                    <MediumSpacer />
                    <FeatureCardRow>
                        <FeatureCardColumn>
                            <TextContainer>{supplierProduct.supplierName}</TextContainer>
                            <SmallTextContainer>{supplierProduct.supplierAddressLine1}</SmallTextContainer>
                            <SmallTextContainer>{supplierProduct.supplierAddressLine2}</SmallTextContainer>
                            <SmallTextContainer>{supplierProduct.supplierAddressLine3}</SmallTextContainer>
                            <SmallTextContainer>{supplierProduct.supplierCity}</SmallTextContainer>
                            <SmallTextContainer>{supplierProduct.supplierPostcode}</SmallTextContainer>
                        </FeatureCardColumn>
                        <FeatureCardColumn>
                            <TextContainer>Cost price: {currencyFormatter.format(supplierProduct.price)}</TextContainer>
                            <MediumSpacer />
                            <TextContainer>Estimated delivery: {supplierProduct.deliveryDay}</TextContainer>
                        </FeatureCardColumn>
                    </FeatureCardRow>
                </FeatureCard>
            )}

            <FeatureCard>
                <FeatureCardTitle>
                    Order
                </FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput autoFocus disabled={inBasket} type="number" id='txtStockLevel' label="Current Stock Level" value={currentStock?.toString() ?? ""} onChange={(event) => handleStockLevelChange(parseFloat(event.target.value))} error={errors.currentStock}></TextInput>
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <TextInput disabled={inBasket} type="number" id='txtQuantityInput' label="Quantity" value={quantity?.toString() ?? ""} onChange={(event) => handleQuantityChange(parseFloat(event.target.value))} error={errors.quantity}></TextInput>
                    </FeatureCardColumn>
                </FeatureCardRow>
                <FeatureCardRow>
                    <FeatureCardSingleColumn>
                        <TextInput multiline={true} disabled={inBasket} id="txtNotes" label="Notes" value={notes} onChange={(event) => setNotes(event.target.value)} />
                    </FeatureCardSingleColumn>
                </FeatureCardRow>
            </FeatureCard>
            <OrderButtons>
                <Button
                    onClick={inBasket ? undefined : addToBasket}
                    label={addToBasketMessage}
                    variant="contained"
                    id="btnAddToBasket"
                    disabled={inBasket}
                    fullWidth={false}
                />
            </OrderButtons>
        </Container >
    );
}

const Container = styled.div`
    display:flex;
    flex-direction:column;
    padding:0px 2px;
`
const ProductName = styled.div`
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;

    padding-bottom:10px;
`;

const ProductImage = styled.div`
margin-top:5px;
img{
    width:150px;
    height:150px;
}
    
    
`

const ProductDescription = styled.div`    
    text-align: center;            
    padding:10px;
    border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};
`;

const ProductDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top:30px;

`;

const ProductDetail = styled.div`
    width: 50%;     
    box-sizing: border-box;  
    margin-bottom:15px;  

    &:nth-child(even){
        text-align:end;
    }
`;


const ProductDetailValue = styled.span`
    margin-left: 5px;
`;


const InStoreContainer = styled.div`
    display:flex;            
    justify-content: center;
    margin-bottom:10px;

`

const InStoreColumn = styled.div`
    width:50%;
`
const OrderButtons = styled.div`
display:flex;
justify-content: flex-end;
margin-top:10px;
`



export { ProductModal };
