import { useEffect } from 'react';

const useEnterKeyListener = (callback: () => void): void => {
    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                callback();
                event.preventDefault();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [callback]);
};

export default useEnterKeyListener;
