import * as React from 'react';
import { Unstable_NumberInput as BaseNumberInput, NumberInputProps } from '@mui/base/Unstable_NumberInput';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../icons/google-icon';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

const CurrencyInput: React.FC<TextFieldProps> = (props) => {
    return (
        <StyledInput
            type="number"
            variant="standard"
            inputProps={{
                step: 0.5,
            }}
            label="Price"
            InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                endAdornment: <InputAdornment position="end">per unit</InputAdornment>,
            }}
            {...props}
        />
    );
};

const StyledInput = styled(TextField)`      

.MuiInputBase-root {    
    outline:0;
    font-family:inherit;    
    .MuiInputAdornment-root{
    p{
    color: ${(props) => props.theme.application.scheme.primary};
}
    }
}

`
export default CurrencyInput;
