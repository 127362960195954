import styled from "styled-components"

export const ProductImage = styled.div`
    width:60px;
    height:60px;
    margin-right:15px;

    span{
    margin-top:10px;
    }

`
export const ProductTitle = styled.div`
font-size:1.1rem;
font-weight:600;
`
export const ProductContainer = styled.div`
    display:flex;
    align-items: center;
`
export const ProductNameContainer = styled.div`
display:flex;
   flex-direction:column;
`