import React from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';

interface CheckboxProps extends MUICheckboxProps {
    label: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
    return (
        <FormControlLabel
            control={<MUICheckbox {...props} />}
            label={label}
        />
    );
};

export default Checkbox;
