import { useState, useEffect, useCallback } from 'react';

interface UseInfiniteScrollOptions {
    dataLength: number;
    totalCount: number;
    fetchMoreData: () => void;
    setLoading: (value: boolean) => void;
    loading: boolean;
    loadingRowRef: React.RefObject<HTMLDivElement>

}

export const useInfiniteScroll = ({
    dataLength,
    totalCount,
    fetchMoreData,
    setLoading,
    loading,
    loadingRowRef
}: UseInfiniteScrollOptions) => {


    useEffect(() => {
        if (!loadingRowRef.current) return;
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry?.isIntersecting && dataLength !== totalCount && !loading) {
                    setLoading(true);
                    setTimeout(() => {
                        fetchMoreData();
                        setLoading(false);
                    }, 300);
                }
            },
            {
                root: null, // Observes within the entire viewport
                rootMargin: "0px",
                threshold: 0.4, // Trigger when the entire loadingRow is in view
            }
        );

        observer.observe(loadingRowRef.current);

        // Cleanup observer on component unmount
        return () => {
            if (loadingRowRef.current) {
                observer.unobserve(loadingRowRef.current);
            }
        };
    }, [dataLength, totalCount, loading]);

};
