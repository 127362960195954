import React, { useEffect } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import Header from '../../components/layout/header';
import styled from 'styled-components';
import { ActiveArea, Content, ContentArea, HeaderArea, Main, Title, TitleArea, VerticalContent } from '../../styles/shared/page';
import ModalHandler from '../modals/modal-handler';
import Toastr from '../../components/notification/toastr';
import { useErrorDisplay } from '../../hooks/useErrorDisplay';
import { useParams } from 'react-router-dom';
import Button from '../../components/controls/button';
import Back from '../../components/layout/back';
import ConfirmModal from '../../components/notification/confirm-modal';


interface LayoutProps {
  showBack: boolean,
  children: React.ReactNode;
}

const OrderLayout: React.FC<LayoutProps> = ({ showBack, children }) => {
  const user = useAppSelector((state => state.user)).user
  const branch = useAppSelector((state => state.branch)).selectedBranch
  const { orderId } = useParams();
  useErrorDisplay();


  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <ConfirmModal></ConfirmModal>
      <Header name={user?.name} team={user?.role} selectedBranch={branch} />
      <VerticalContent>
        <HeaderArea>
          <TitleArea>
            {showBack && (
              <Back></Back>
            )}
            <Title>Order #{orderId}
            </Title>
            <Buttons>
            </Buttons>
          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>
              {children}
            </ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default OrderLayout;

const Buttons = styled.div`
button{
margin-right:30px;
}
`
