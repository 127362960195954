import createClient, { Middleware } from "openapi-fetch";
import type { paths } from "./schema/order-link-api-schema"; // generated by openapi-typescript

const API_BASE_URL = process.env.REACT_APP_API_URL;
const MAX_RETRIES = 3; // Set the maximum number of retries

let isRefreshing = false;
let failedQueue: { resolve: (value?: unknown) => void; reject: (reason?: any) => void; }[] = [];

/**
 * Processes the failed request queue.
 * @param error - The error object, if any.
 */
const processQueue = (error?: any) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

/**
 * Refreshes the access token.
 * @returns A promise that resolves with the new access token.
 */
const refreshAccessToken = async (): Promise<string> => {
    try {
        const response = await fetch(`${API_BASE_URL}/v1/api/authentication/refresh`, {
            method: 'POST',
            credentials: 'include', // Ensure cookies are sent with the request
        });
        const data = await response.json();
        return data.accessToken;
    } catch (error) {
        console.error('Failed to refresh token', error);
        throw error;
    }
};

const myMiddleware: Middleware = {
    async onRequest({ request, options }) {
        // Optionally add authorization headers here if needed    

        return request;
    },
    async onResponse({ request, response, options }) {
        if (response.status === 401) {
            const wwwAuthenticate = response.headers.get('www-authenticate');

            if (wwwAuthenticate && wwwAuthenticate.includes('error="invalid_token"')) {

                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({
                            resolve: () => {
                                // Retry the request after the token is refreshed
                                const retryRequest = new Request(request.url, {
                                    ...request,
                                    credentials: 'include', // Ensure credentials are included
                                    headers: {
                                        ...request.headers, // Preserve other headers                     
                                    },
                                });
                                return fetchWithRetry(retryRequest, 0); // Start with 0 retries
                            },
                            reject,
                        });
                    });
                }

                isRefreshing = true;

                try {
                    await refreshAccessToken();

                    // Retry the original request with the new token
                    const retryRequest = new Request(request.url, {
                        ...request,
                        credentials: 'include', // Ensure credentials are included
                        headers: {
                            ...request.headers, // Preserve other headers                           
                        },
                    });

                    return fetchWithRetry(retryRequest, 0); // Start with 0 retries
                } catch (error) {
                    console.log(error);
                    processQueue(error);
                    // Redirect to login on token refresh failure
                    window.location.href = '/login';
                    throw error;
                } finally {
                    isRefreshing = false;
                }
            }
        }

        return response;
    },
};

/**
 * Fetch with retry logic.
 * @param request - The request to retry.
 * @param retryCount - The current retry count.
 * @returns A promise that resolves with the response or rejects with an error.
 */
const fetchWithRetry = async (request: Request, retryCount: number): Promise<Response> => {
    try {
        const response = await fetch(request);
        if (response.ok || retryCount >= MAX_RETRIES) {
            return response;
        } else {
            throw new Error(`Failed with status: ${response.status}`);
        }
    } catch (error) {
        if (retryCount < MAX_RETRIES) {
            console.log(`Retrying request (${retryCount + 1}/${MAX_RETRIES})...`);
            return fetchWithRetry(request, retryCount + 1);
        } else {
            console.error('Maximum retry limit reached.');
            throw error;
        }
    }
};

const client = createClient<paths>({ baseUrl: API_BASE_URL, credentials: "include" });

// Register middleware
client.use(myMiddleware);

export default client;
