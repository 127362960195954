import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import { ScrollableCard } from "../../styles/shared/card";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { blurAnimation } from "../../styles/shared/loading";
import LoadingSpinner from "./loading-spinner";
import { TextContainer } from "../../styles/shared/table";

export interface ColumnConfig {
  label?: string;
  hidden?: boolean;
  name?: string;
  render?: (value: any, row: { [key: string]: any }) => React.ReactNode;
  order?: number;
  align?: string | "center" | "left";
  colspan?: number;
}

interface TableViewProps {
  id: string;
  columnConfig?: { [key: string]: ColumnConfig };
  actionButton?: (row: { [key: string]: any }) => React.ReactNode;
  fetchMoreData?: () => void;
  data: { [key: string]: any }[];
  totalCount: number;
  preLoadedData?: boolean;
  emptyText: string;
  onRowClick?: (row: { [key: string]: any }) => void;
  showHeaders?: boolean;
}

export default function TableView({
  id,
  columnConfig = {},
  actionButton,
  fetchMoreData,
  data,
  totalCount,
  emptyText,
  preLoadedData = false,
  showHeaders = false,
  onRowClick,
}: TableViewProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const rowRef = useRef<HTMLDivElement>(null);
  const loadingRowRef = useRef<HTMLDivElement>(null);

  useInfiniteScroll({
    dataLength: data.length,
    totalCount: totalCount,
    fetchMoreData: fetchMoreData || (() => {}),
    setLoading,
    loading,
    loadingRowRef,
  });

  useEffect(() => {
    if (data.length === 0 && !preLoadedData && fetchMoreData) {
      setPageLoading(true);
      fetchMoreData(); // Fetch initial data if none is provided
      setPageLoading(false);
    }
  }, [data, fetchMoreData]);

  if (data.length === 0) {
    return (
      <ScrollableCard
        background={theme.application.table.background}
        scrollbar={theme.application.scrollBar}
      >
        <NoContent>
          <TextContainer>{emptyText}</TextContainer>
        </NoContent>
      </ScrollableCard>
    );
  }

  if (pageLoading) {
    return (
      <ScrollableCard
        background={theme.application.table.background}
        scrollbar={theme.application.scrollBar}
      >
        <NoContent>
          <LoadingSpinner></LoadingSpinner>
        </NoContent>
      </ScrollableCard>
    );
  }

  // Derive columns from the keys of the first row
  const columns =
    data.length > 0
      ? Object.keys(data[0] ?? []).filter(
          (column) => !columnConfig[column]?.hidden
        )
      : [];

  const sortedColumns = columns.sort((a, b) => {
    const orderA = columnConfig[a]?.order ?? Number.MAX_SAFE_INTEGER;
    const orderB = columnConfig[b]?.order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  // the below calculates the required grid columns including the colspan attributes.
  const sumOfColspans = sortedColumns.reduce((sum, column) => {
    const colspan = columnConfig[column]?.colspan || 1;
    return sum + (colspan > 1 ? colspan : 0);
  }, 0);
  const numberOfColspanColumns = columns.filter(
    (column) => (columnConfig[column]?.colspan ?? 0) > 1
  ).length;
  const columnLength =
    columns.length +
    sumOfColspans -
    numberOfColspanColumns +
    (actionButton ? 1 : 0);

  return (
    <ScrollableCard
      background={theme.application.table.background}
      scrollbar={theme.application.scrollBar}
    >
      <TableWrapper id={id}>
        {showHeaders && (
          <TableHeader>
            <HeaderRow $columnCount={columnLength}>
              {sortedColumns.map((header) => {
                const config = columnConfig[header];
                return (
                  <TableCell
                    $header={true}
                    $colSpan={config?.colspan}
                    key={`header_${header}`}
                    $center={(config?.align ?? "") == "center"}
                  >
                    {config?.name ?? header}{" "}
                  </TableCell>
                );
              })}
            </HeaderRow>
          </TableHeader>
        )}
        <TableBody>
          {data.map((row) => (
            <TableRow
              $columnCount={columnLength}
              onClick={() => (onRowClick ? onRowClick(row) : null)}
              ref={rowRef}
              key={row.id}
            >
              {sortedColumns.map((column) => (
                <TableCell
                  $colSpan={columnConfig[column]?.colspan}
                  $center={(columnConfig[column]?.align ?? "") == "center"}
                  key={column}
                >
                  {columnConfig[column]?.render
                    ? columnConfig[column].render!(row[column], row)
                    : row[column]}
                </TableCell>
              ))}
              {actionButton && (
                <TableCell $center>{actionButton(row)}</TableCell>
              )}
            </TableRow>
          ))}

          {totalCount != data.length && (
            <LoadingRow ref={loadingRowRef}>
              {sortedColumns.map((_column, index) => (
                <LoadingCell key={`load${index}`}></LoadingCell>
              ))}
              <LoadingCell></LoadingCell>
            </LoadingRow>
          )}
        </TableBody>
      </TableWrapper>
    </ScrollableCard>
  );
}

const TableWrapper = styled.div`
  display: block;
  width: 100%;
  border-collapse: collapse;
  height: 100%;
`;

const TableBody = styled.div`
  display: block;
  height: 100%;
`;

const TableHeader = styled.div`
  display: block;
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${(props) => props.theme.application.scheme.primary};
  color: ${(props) => props.theme.application.scheme.contrast};
`;

interface TableRowProps {
  $columnCount: number;
}

const HeaderRow = styled.div<TableRowProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.$columnCount},
    minmax(100px, 1fr)
  );

  cursor: pointer;
  align-items: center;
`;

const TableRow = styled.div<TableRowProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.$columnCount},
    minmax(100px, 1fr)
  );
  border-bottom: 1px solid ${(props) => props.theme.application.border};
  cursor: pointer;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.application.table.hover};
  }
`;

interface TableCellProps {
  $center?: boolean; // Declare the prop here
  $colSpan?: number;
  $header?: boolean;
}
const TableCell = styled.div<TableCellProps>`
  padding: 10px;
  background: ${(props) => props.theme.application.table.cellBackground};
  border-radius: 4px;
  text-align: ${(props) => (props.$center ? "center" : "left")};
  grid-column: ${(props) =>
    props.$colSpan ? `span ${props.$colSpan}` : "auto"};
  text-transform: ${(props) => (props.$header ? "capitalize" : "")};
  font-weight: ${(props) => (props.$header ? "600" : "")};
`;

const LoadingRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px 0;
  animation: ${blurAnimation} 0.5s infinite;
  height: 50px;
`;

const LoadingCell = styled.div`
  padding: 20px;
  border-radius: 15px;
  padding: 20px;
  border-radius: 6px;
  background: ${(props) => props.theme.application.placeholder};
  margin: 0px 16px;
`;

const NoContent = styled.div`
  text-align: center;
  margin-top: 21%;
`;
