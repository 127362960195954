import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { ApplicationRole } from '../../models/authentication/application-roles';
import OwnerLayout from '../../pages/layouts/owner-layout';

export const OwnerRoute = () => {
    const { initialized, loading, authenticated, role } = useAuth();
    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }

    if (role != ApplicationRole.Owner) {
        return <Navigate to="/home" replace />;
    }

    return (
        <OwnerLayout>
            <Outlet />
        </OwnerLayout>
    )
};