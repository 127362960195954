import styled from "styled-components";

export const TopNavContainer = styled.div`
    width:100%;
    display:flex;
    border-bottom: 1px solid ${props => props.theme.application.border};
    color: ${props => props.theme.application.text};
    background: ${props => props.theme.application.navbar.background};    
    height: 7%;
`
export const SideBar = styled.div`
    flex: 0 0 275px; 
    height:100%;
    box-shadow: 8px 2px 11px rgba(0, 0, 0, 0.1);  
    
    display:flex;
    flex-direction:column;
    font-size:1.1rem;    
    z-index:2;

    @media (max-width: 1200px) {
      flex: 0 0 230px;
    }
`

export const HeaderArea = styled.div`
  height:10%;      
   background: ${props => props.theme.application.header}; 

     @media (max-height: 900px ) {
       height:15%;
    }
       
     @media (max-height: 640px ) {
       height:20%;
    }

`

export const ContentArea = styled.div`
  background: ${props => props.theme.application.body}; 
  width: 100%;    
  height: 100%;
`

export const ActiveArea = styled.div`
   padding:40px 10px 0px 20px;  
   height: 90%;
`


export const VerticalContent = styled.div`
  display: flex;
  flex-direction:column;  
  color: ${props => props.theme.application.text};   
  height:93%;

  main{
  height:90%;
  }
`

export const Content = styled.div`
  display: flex;
  color: ${props => props.theme.application.text};   
  height:93%;
`

export const Main = styled.main`
height:100%;
flex:1;
`

export const TitleArea = styled.div`
  padding: 10px 30px;
`
export const Title = styled.h2`
    margin-bottom: 0px;
    font-size: 2rem;
    margin-top: 0px;
    button{
    margin-left:30px;
    }
`