import { Password } from "@mui/icons-material";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import AuthenticationService from "../../../services/authentication/authentication-service";
import { ApplicationUser } from "../../../models/authentication/application-user";
import { ApplicationRole } from "../../../models/authentication/application-roles";
import { setUser } from "./user-slice";
import { clearError } from "../notification/error-slice";

interface LoginState {
    email: string;
    password: string;
    loading: boolean;
    errors: {
        email: string | null;
        password: string | null;
        pageError: string | null;
    }
    user: ApplicationUser;
}

const initialState: LoginState = {
    email: "",
    password: "",
    loading: false,
    errors: {
        email: null,
        password: null,
        pageError: null,
    },
    user: {
        username: "",
        name: "",
        branches: [],
        role: ApplicationRole.Owner,
    },
}

export const authenticate = createAsyncThunk<void, void, { state: RootState, dispatch: AppDispatch }>(
    'login/authenticate',
    async (_, thunkApi) => {
        const state = thunkApi.getState();
        try {
            const authenticationService = new AuthenticationService();
            let applicationUser = await authenticationService.login(state.login.email, state.login.password)
            thunkApi.dispatch(setUser(applicationUser));
            thunkApi.dispatch(clearError());
        }
        catch (error: any) {
            const customErrorMessages = {
                401: "Login failed: email and password is incorrect",
                406: "The user is not assigned to any branches"
            };

            const apiError = ApplicationError.handleApiError(error, customErrorMessages);
            return thunkApi.rejectWithValue(apiError)
        }
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setErrors: (state, action: PayloadAction<{ email: string | null; password: string | null; pageError: string | null }>) => {
            state.errors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authenticate.pending, (state) => {
            // Handle pending state if needed
            state.loading = true;
        });
        builder.addCase(authenticate.fulfilled, (state) => {
            // Handle success state if needed
            state.loading = false;
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            // Handle failure state if needed
            const message = action.payload as string;
            state.loading = false;
            state.errors.pageError = message
        });

    }
});

export const { setEmail, setPassword, setErrors } = loginSlice.actions;

export default loginSlice.reducer

