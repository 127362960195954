import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationDepartment } from "../../../models/organisation/application-department";
import DepartmentService from "../../../services/organisation/department-service";
import { ApplicationError } from "../../../models/errors/application-error";

interface DepartmentState {
    departments: ApplicationDepartment[];
    loading: boolean;
    selectedDepartment: ApplicationDepartment | null;
    error: string | null;
}

const initialState: DepartmentState = {
    departments: [],
    loading: false,
    selectedDepartment: null,
    error: null,
};

export const fetchDepartments = createAsyncThunk<ApplicationDepartment[], void, { state: RootState }>(
    'departments/fetch',
    async (_, { rejectWithValue }) => {
        const departmentService = new DepartmentService();
        try {
            return await departmentService.get();
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        setSelectedDepartment: (state, action: PayloadAction<ApplicationDepartment | null>) => {
            state.selectedDepartment = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDepartments.fulfilled, (state, action: PayloadAction<ApplicationDepartment[]>) => {
                state.departments = action.payload;
                state.loading = false;
            })
            .addCase(fetchDepartments.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setSelectedDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
