import React, { useEffect } from "react";
import TableView, {
  ColumnConfig,
} from "../../../components/controls/table-view";
import {
  SmallTextContainer,
  TextContainer,
} from "../../../styles/shared/table";
import Button from "../../../components/controls/button";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { fetchProduct } from "../../../store/features/product/product-slice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  fetchSupplierProducts,
  insertSupplierProductPrice,
  resetSupplierProducts,
  setNewPrice,
  toggleEditPrice,
} from "../../../store/features/supplier/supplier-product-slice";
import { Navigate } from "react-router-dom";
import {
  ProductContainer,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../../styles/shared/product";
import Image from "../../../components/controls/image";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../../components/icons/google-icon";
import { Description } from "@mui/icons-material";
import { currencyFormatter } from "../../../helpers/formatters";
import styled from "styled-components";
import CurrencyInput from "../../../components/controls/currency-input";
import { ModalID } from "../../../constants/modal-constants";
import { openModal } from "../../../store/features/modal/modal-slice";

interface SupplierProductProps {
  supplierId: number;
}
export default function SupplierProducts({ supplierId }: SupplierProductProps) {
  const products = useAppSelector((state) => state.supplierProducts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetSupplierProducts());
    };
  }, []);

  const fetchMoreData = () => {
    dispatch(fetchSupplierProducts(supplierId));
  };

  const renderActionButton = (row: any) => (
    <Button
      variant="outlined"
      id={row.Name}
      label="Edit product"
      isPrimary={true}
      fullWidth={false}
      onClick={() =>
        dispatch(
          openModal({
            id: ModalID.EditSupplierProduct,
            data: { id: row.id },
          })
        )
      }
    />
  );

  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    productId: {
      hidden: true,
    },
    supplierId: {
      hidden: true,
    },
    size: {
      hidden: true,
    },
    description: {
      hidden: true,
    },
    image: {
      hidden: true,
    },
    newPrice: {
      hidden: true,
    },
    editPrice: {
      hidden: true,
    },
    plu: {
      hidden: true,
    },
    name: {
      order: 1,
      name: "Product",
      colspan: 2,
      render: (value: string, row: any) => (
        <ProductContainer>
          <ProductImage>
            {row.productId ? (
              <Image image={row.image}></Image>
            ) : (
              <GoogleIcon
                tooltip="Not mapped"
                id={ICON_ID.QuestionMark}
                isWarning
                size={ICON_SIZES.Large}
              />
            )}
          </ProductImage>
          <ProductNameContainer>
            <ProductTitle>{value}</ProductTitle>
            <div>
              {row.size}
              {row.description && " - " + row.description}
            </div>
            {row.plu && <SmallTextContainer>Plu: {row.plu}</SmallTextContainer>}
          </ProductNameContainer>
        </ProductContainer>
      ),
    },
    price: {
      order: 2,
      render: (value: number | null, row: any) => {
        if (row.editPrice) {
          return (
            <FieldContainer>
              <CurrencyInput
                value={row.newPrice}
                onChange={(value) =>
                  dispatch(
                    setNewPrice({
                      id: row.id,
                      price: parseFloat(value.target.value),
                    })
                  )
                }
              ></CurrencyInput>
              <VerticalIcons>
                <Button
                  variant="text"
                  id={`btnSave${row.Name}`}
                  label=""
                  icon={ICON_ID.Save}
                  isPrimary={true}
                  fullWidth={false}
                  onClick={() => dispatch(insertSupplierProductPrice(row.id))}
                />
                <Button
                  variant="text"
                  id={`btnClose${row.Name}`}
                  label=""
                  icon={ICON_ID.Close}
                  isPrimary={true}
                  fullWidth={false}
                  onClick={() => dispatch(toggleEditPrice(row.id))}
                />
              </VerticalIcons>
            </FieldContainer>
          );
        }
        return (
          <PriceContainer>
            <PriceText>
              {value ? currencyFormatter.format(value) : <>Not set</>}
            </PriceText>
            <EditButton
              variant="text"
              id={row.name}
              label=""
              icon={ICON_ID.Edit}
              isPrimary={true}
              fullWidth={false}
              onClick={() => dispatch(toggleEditPrice(row.id))}
            />
          </PriceContainer>
        );
      },
    },
    updated: {
      render: (value: Date) => (
        <TextContainer>
          {new Date(value).toLocaleDateString()}{" "}
          {new Date(value).toLocaleTimeString()}
        </TextContainer>
      ),
    },
    active: {
      render: (value: boolean) => (
        <TextContainer>
          {value ? (
            <GoogleIcon
              tooltip="Active"
              id={ICON_ID.CheckCircle}
              size={ICON_SIZES.Large}
              isSuccess
            />
          ) : (
            <GoogleIcon
              tooltip="Disabled"
              id={ICON_ID.Close}
              size={ICON_SIZES.Large}
              isDanger
            />
          )}
        </TextContainer>
      ),
    },
  };
  return (
    <TableContainer>
      <TableView
        id="tblProducts"
        emptyText="No products"
        fetchMoreData={fetchMoreData}
        totalCount={products.products?.count ?? 0}
        data={products?.products?.products ?? []}
        columnConfig={columnConfig}
        actionButton={renderActionButton}
        showHeaders={true}
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  flex: 0 0 70vh;
  min-height: 0; /* workaround for scrolling fix when flipping to column flex */
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const VerticalIcons = styled.div`
  display: flex;
  flex-direction: column;

  button {
    span {
      font-size: 1.1rem;
    }
  }
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const PriceText = styled.span`
  flex-grow: 1;
`;

const EditButton = styled(Button)`
  flex-shrink: 0;
`;
