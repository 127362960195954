import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import ProductService from "../../../services/product/product-service";
import { ApplicationProductList } from "../../../models/product/application-product-list";
import { ApplicationDepartmentCategoryList } from "../../../models/organisation/application-department-category-list";
import { ApplicationDepartmentCategory } from "../../../models/organisation/application-department-category";
import DepartmentService from "../../../services/organisation/department-service";
import { ApplicationCategory } from "../../../models/product/application-category";
import { ApplicationProduct } from "../../../models/product/application-product";

export const fetchDepartmentCategories = createAsyncThunk<ApplicationDepartmentCategoryList | null, number, { state: RootState }>(
    'continousproducts/department/categories/fetch',
    async (departmentId, { getState, rejectWithValue }) => {
        const departmentService = new DepartmentService();
        const state = getState().continousProduct;
        try {
            return await departmentService.getCategories(departmentId);
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const fetchProducts = createAsyncThunk<ApplicationProductList | null, number, { state: RootState }>(
    'continousproducts/products/fetch',
    async (departmentCategoryId, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            var currentPosition: number = state.continousProduct.products?.currentPosition ?? 0;
            var fetchNext: number = 10;
            var count: number = state.continousProduct.products?.count ?? -1;

            if (currentPosition != count) {
                return await productService.getProductsByDepartmentCategory(state.branch.selectedBranch!.id, departmentCategoryId, currentPosition, fetchNext, false);
            }

            return null;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const updateBranchProduct = createAsyncThunk<{ productId: number, active: boolean }, { productId: number, active: boolean }, { state: RootState }>(
    'continousproducts/branchProduct/update',
    async ({ productId, active }, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            await productService.upsertBranchProduct(state.branch.selectedBranch!.id, productId, active)
            return { productId, active };
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);



interface ContinousProductState {
    departmentCategories: ApplicationDepartmentCategoryList | null,
    selectedDepartmentCategory: ApplicationDepartmentCategory | null,
    completedCategories: ApplicationDepartmentCategory[]
    products: ApplicationProductList | null,
    currentProduct: ApplicationProduct | null
}


const initialState: ContinousProductState = {
    departmentCategories: null,
    selectedDepartmentCategory: null,
    products: null,
    currentProduct: null,
    completedCategories: [],
};

const continiousProductSlice = createSlice({
    name: 'continousproducts',
    initialState,
    reducers: {
        setSelectedDepartmentCategory: (state, action: PayloadAction<ApplicationDepartmentCategory | null>) => {
            state.selectedDepartmentCategory = action.payload;
        },
        setCurrentProduct: (state, action: PayloadAction<ApplicationProduct | null>) => {
            state.currentProduct = action.payload;
        },
        setCompletedCategories: (state, action: PayloadAction<ApplicationDepartmentCategory | null>) => {
            if (action.payload && state.completedCategories.indexOf(action.payload) == -1) {
                state.completedCategories.push(action.payload);
            }
        },
        resetCompletedCategories: (state) => {
            state.completedCategories = []
        },
        resetProducts: (state) => {
            state.products = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartmentCategories.fulfilled, handleDepartmentCategoriesFullfilled)
            .addCase(fetchProducts.fulfilled, handleFetchProductsFulfilled)
            .addCase(updateBranchProduct.fulfilled, handleUpdateBranchProduct)
    },
});


const handleUpdateBranchProduct = (
    state: ContinousProductState,
    action: PayloadAction<{ productId: number, active: boolean }>
) => {
    if (!action.payload.active) {
        const productIndex = state.products?.products.findIndex(x => x.id === action.payload.productId);
        if (productIndex && productIndex !== -1) {
            state.products?.products.splice(productIndex, 1);
            if (state.products?.count) {
                state.products.count = state.products.count - 1;
            }
        }
    }
}

const handleDepartmentCategoriesFullfilled = (
    state: ContinousProductState,
    action: PayloadAction<ApplicationDepartmentCategoryList | null>
) => {
    state.departmentCategories = action.payload;
}


const handleFetchProductsFulfilled = (
    state: ContinousProductState,
    action: PayloadAction<ApplicationProductList | null>
) => {
    if (action.payload) {
        const { products, currentPosition, count } = action.payload;
        if (state.products) {
            state.products.products = state.products.products.concat(products);
            state.products.currentPosition = currentPosition;
            state.products.count = count;
        } else {
            state.products = action.payload;
            state.currentProduct = action.payload.products[0] ?? null;
        }
    }
};



export const { setCurrentProduct, setSelectedDepartmentCategory, resetProducts, setCompletedCategories, resetCompletedCategories } = continiousProductSlice.actions;
export default continiousProductSlice.reducer;
