import { useEffect } from "react";
import { useAppSelector } from "./useAppSelector"
import { useAppDispatch } from "./useAppDispatch";
import { showError } from "../components/notification/toastr-actions";
import { clearError } from "../store/features/notification/error-slice";

export const useErrorDisplay = () => {
    const error = useAppSelector((state => state.error));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (error.message != null) {
            dispatch(showError(error.message));
            dispatch(clearError());
        }
    }, [error.message])

}