import { RootState } from "../store/store";
import ProductModal from "../pages/modals/product-modal";
import { ModalComponents, ModalID } from "../constants/modal-constants";
import { useMemo } from "react";
import { useAppSelector } from "./useAppSelector";

interface ModalContent {
    component: React.ComponentType<any> | null;
    data: any | null
}

export const useModal = (): ModalContent => {
    const modal = useAppSelector((state: RootState) => state.modal);

    const component = useMemo(() => {
        if (!modal.id) {
            return null;
        }

        return ModalComponents[modal.id];
    }, [modal]);

    if (!modal.id) {
        return {
            component: null,
            data: null,
        };
    }

    return {
        component,
        data: modal.data,
    };
};

