export class ApplicationError extends Error {
  constructor(errorMessage: string) {
    super(errorMessage)
    Object.setPrototypeOf(this, ApplicationError.prototype);
  }

  /// to provide custom messages call function like this
  //const customErrorMessages = {
  //401: "Custom 401 message",
  // 500: "Custom 500 message",
  // 404: "Custom 404 message"
  // };
  // handleApiError(error, customErrorMessages);
  static handleApiError(error: any, customMessages: { [key: number]: string } = {}): string {
    let apiError: ApplicationError = new ApplicationError(error.message);
    if (error.response) {
      const status = error.response.status;
      const defaultMessages: { [key: number]: string } = {
        401: "You are not authorised for this resource",
        500: "An error occurred connecting to service",
        404: "Resource not found",
      };

      const message = customMessages[status] || defaultMessages[status];

      if (message) {
        apiError = new ApplicationError(message);
      }
    }

    console.error(error, apiError);
    return apiError.message;
  }
};