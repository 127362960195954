import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalID } from "../../../constants/modal-constants";

interface ModalState {
    id: ModalID | null
    data?: any | null
}

const initialState: ModalState = {
    id: null,
    data: null,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<ModalState>) => {
            state.id = action.payload.id;
            state.data = action.payload.data
        },
        closeModal: (state) => {
            state.id = null;
            state.data = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
