import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { deleteBasketItem, fetchBasket, fetchBasketItems, updateBasketItemQuantity, updateBasketItemStock } from '../../store/features/ordering/basket/basket-slice';
import TableView, { ColumnConfig } from '../../components/controls/table-view';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../../components/icons/google-icon';
import Button from '../../components/controls/button';
import styled from 'styled-components';
import { ProductContainer, ProductImage, ProductNameContainer, ProductTitle } from '../../styles/shared/product';
import Image from '../../components/controls/image';
import TextInput from '../../components/controls/text-input';
import { closeConfirm, openConfirm } from '../../store/features/notification/confirm-slice';
import { ApplicationApprovalStatus } from '../../models/order/application-approval-status';
import { SmallTextContainer, TableContainer } from '../../styles/shared/table';



interface InlineFieldEdit {
    [basketItemId: number]: {
        value: number,
        showEdit: boolean;
    };
}

export default function Basket() {
    const basket = useAppSelector((state => state.basket));
    const selectedBranch = useAppSelector((state => state.branch)).selectedBranch;
    const dispatch = useAppDispatch();

    const [currentStockEdit, setCurrentStockEdit] = useState<InlineFieldEdit>({
        0: { value: 0, showEdit: false },
    });

    const [quantityEdit, setQuantityEdit] = useState<InlineFieldEdit>({
        0: { value: 0, showEdit: false },
    });

    useEffect(() => {
        if (basket.basket != null) {
            dispatch(fetchBasketItems(basket.basket.id));
        } else {
            dispatch(fetchBasket(selectedBranch!.id));
        }
    }, [basket.basket, selectedBranch])


    const toggleFieldEdit = (id: number, value: number, setFunction: React.Dispatch<React.SetStateAction<InlineFieldEdit>>) => {
        setFunction(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                showEdit: !prevState[id]?.showEdit,
                value: value
            }
        }));
    };

    const setFieldValue = (id: number, value: string, setFunction: React.Dispatch<React.SetStateAction<InlineFieldEdit>>) => {
        var newValue: number = parseFloat(value);

        setFunction(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id] ?? 0,
                showEdit: prevState[id]?.showEdit ?? false,
                value: newValue
            }
        }));
    };

    const fetchMoreData = () => {
        if (basket.basket != null) {
            dispatch(fetchBasketItems(basket.basket.id))
        }
    };

    const updateCurrentStock = (id: number) => {
        var currentStock = currentStockEdit[id]?.value ?? 0;

        setCurrentStockEdit(prevState => {
            const existingState = prevState[id] || { value: 0, showEdit: true }; // Default values if not present

            return {
                ...prevState,
                [id]: {
                    ...existingState, // Spread the existing state, which is guaranteed to be non-null
                    showEdit: false
                }
            };
        });
        dispatch(updateBasketItemStock({ basketItemId: id, currentStock }));
    }

    const updateQuantity = (id: number) => {
        var quantity = quantityEdit[id]?.value ?? 0;

        setQuantityEdit(prevState => {
            // Ensure prevState[id] exists
            const existingState = prevState[id] || { value: 0, showEdit: true }; // Provide default values

            return {
                ...prevState,
                [id]: {
                    ...existingState,
                    value: quantity,
                    showEdit: false
                }
            };
        });

        dispatch(updateBasketItemQuantity({ basketItemId: id, quantity: quantity }));
    }



    const columnConfig: { [key: string]: ColumnConfig } = {
        id: {
            hidden: true,
        },
        basketId: {
            hidden: true,
        },
        productId: {
            hidden: true,
        },
        productDescription: {
            hidden: true,
        },
        productImage: {
            hidden: true,
        },
        productSize: {
            hidden: true,
        },
        plu: {
            hidden: true,
        },
        currentStock: {
            order: 3,
            name: "Current stock",
            render: (value: number, row: any) => (
                <>
                    {currentStockEdit[row.id]?.showEdit ? (
                        <FieldContainer>
                            <TextInput type="number" label='In stock' id={`txtStock${row.id}`} value={currentStockEdit[row.id]?.value?.toString() || ''} onChange={(value) => setFieldValue(row.id, value.target.value, setCurrentStockEdit)}></TextInput>
                            <VerticalIcons>
                                <Button variant="text" id={row.Name} label="" icon={ICON_ID.Save} isPrimary={true} fullWidth={false} onClick={() => updateCurrentStock(row.id)} />
                                <Button variant="text" id={row.Name} label="" icon={ICON_ID.Close} isPrimary={true} fullWidth={false} onClick={() => toggleFieldEdit(row.id, value, setCurrentStockEdit)} />
                            </VerticalIcons>
                        </FieldContainer>
                    ) : (
                        <>
                            In stock: {currentStockEdit[row.id]?.value ? currentStockEdit[row.id]?.value : value}
                            <Button variant="text" id={row.Name} label="" icon={ICON_ID.Edit} isPrimary={true} fullWidth={false} onClick={() => toggleFieldEdit(row.id, value, setCurrentStockEdit)} />
                        </>
                    )}
                </>
            )
        },
        productName: {
            order: 1,
            name: "Product",
            colspan: 3,
            render: (value: string, row: any) => (
                <ProductContainer>

                    {row.approvalStatus == ApplicationApprovalStatus.AwaitingApproval ?
                        <GoogleIcon tooltip='Awaiting approval' id={ICON_ID.Error} isWarning size={ICON_SIZES.Large}></GoogleIcon>
                        : <GoogleIcon tooltip='Approved' id={ICON_ID.CheckCircle} size={ICON_SIZES.Large} isSuccess></GoogleIcon>}

                    <ProductImage>
                        <Image image={row.productImage}></Image>
                    </ProductImage>
                    <ProductNameContainer>
                        <ProductTitle>{value}</ProductTitle>
                        <div>{row.productSize} - {row.productDescription} </div>
                        <SmallTextContainer>Plu: {row.plu}</SmallTextContainer>
                    </ProductNameContainer>
                </ProductContainer>
            )
        },
        quantity: {
            render: (value: number, row: any) => (
                <>
                    {quantityEdit[row.id]?.showEdit ? (
                        <FieldContainer>
                            <TextInput type="number" id='txtQuantityInput' label="Quantity" value={quantityEdit[row.id]?.value ? quantityEdit[row.id]?.value?.toString() || '' : value.toString()} onChange={(value) => setFieldValue(row.id, value.target.value, setQuantityEdit)}></TextInput>
                            <VerticalIcons>
                                <Button variant="text" id={row.Name} label="" icon={ICON_ID.Save} isPrimary={true} fullWidth={false} onClick={() => updateQuantity(row.id)} />
                                <Button variant="text" id={row.Name} label="" icon={ICON_ID.Close} isPrimary={true} fullWidth={false} onClick={() => toggleFieldEdit(row.id, value, setQuantityEdit)} />
                            </VerticalIcons>
                        </FieldContainer>
                    ) :
                        <>
                            Qty: {quantityEdit[row.id]?.value ? quantityEdit[row.id]?.value : value}
                            <Button variant="text" id={row.Name} label="" icon={ICON_ID.Edit} isPrimary={true} fullWidth={false} onClick={() => toggleFieldEdit(row.id, value, setQuantityEdit)} />
                        </>
                    }
                </>
            )
        },
        notes: {
            render: (value: string) => (
                <SmallTextContainer>
                    {value}
                </SmallTextContainer>
            )
        },
        approvalStatus: {
            hidden: true
        }
    };

    const handleDeleteClick = (row: any) => {
        const onConfirm = () => {
            dispatch(deleteBasketItem(row.id));
            dispatch(closeConfirm())
        }

        const onCancel = () => {
            dispatch(closeConfirm())
        }

        dispatch(openConfirm({
            title: "Remove " + row.productName + "?",
            message: "Are you sure you want to remove this product from your basket?",
            show: true,
            data: {
                onConfirm: onConfirm,
                onCancel: onCancel,
            }
        }))

    }

    const renderDeleteButton = (row: any) => (
        <DeleteContainer>
            <Button variant="text" id={row.Name} label="" icon={ICON_ID.Delete} isDanger={true} fullWidth={false} onClick={() => handleDeleteClick(row)} />
        </DeleteContainer>
    );

    return (
        <TableContainer>
            <TableView
                id="basketTable"
                emptyText='Your basket is empty'
                fetchMoreData={fetchMoreData}
                totalCount={basket.basketItems?.count ?? 0}
                data={basket.basketItems?.items ?? []}
                actionButton={renderDeleteButton}
                columnConfig={columnConfig}
                preLoadedData={true}
                showHeaders={true}
            />
        </TableContainer>
    )
}

const FieldContainer = styled.div`
    display:flex;    
    align-items: center;
    justify-content: space-evenly;
`
const VerticalIcons = styled.div`
    display:flex;
    flex-direction:column;

    button{
        span{        
            font-size:1.1rem;
        }
    }
`

const DeleteContainer = styled.div`
    button{
        span{
        font-size:1.5rem;
        }
    }

`