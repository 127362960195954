import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApplicationUser } from "../../../models/authentication/application-user";
import { AppDispatch, RootState } from "../../store";
import AuthenticationService from "../../../services/authentication/authentication-service";
import { ApplicationError } from "../../../models/errors/application-error";

interface UserState {
  user: ApplicationUser | null
  authenticated: boolean,
  loading: boolean
  failedAutoLogin: boolean
}

const initialState: UserState = {
  user: null,
  authenticated: false,
  loading: true,
  failedAutoLogin: false
}

export const getLoggedInUser = createAsyncThunk<ApplicationUser, void, { state: RootState, dispatch: AppDispatch }>(
  'user/getuser',
  async (_, thunkApi) => {
    try {
      const authenticationService = new AuthenticationService();
      let applicationUser = await authenticationService.getUserInfo();
      return applicationUser;
    }
    catch (error) {
      const apiError = ApplicationError.handleApiError(error);
      return thunkApi.rejectWithValue(apiError)
    }
  }
);

export const logoutUser = createAsyncThunk<void, void, { state: RootState, dispatch: AppDispatch }>(
  'user/logout',
  async (_, thunkApi) => {
    try {
      const authenticationService = new AuthenticationService();
      await authenticationService.logout();
      window.location.href = "/login"
    }
    catch (error) {
      const apiError = ApplicationError.handleApiError(error);
      return thunkApi.rejectWithValue(apiError)
    }
  }
);

export const userSelectorSlice = createSlice({
  name: 'userSelector',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<ApplicationUser>) => {
      state.user = action.payload;
      state.authenticated = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedInUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoggedInUser.fulfilled, (state, action) => {
      const user = action.payload as ApplicationUser
      state.user = user;
      state.authenticated = true;
      state.loading = false;
    });
    builder.addCase(getLoggedInUser.rejected, (state) => {
      state.loading = false;
      state.authenticated = false;
      state.user = null;
      state.failedAutoLogin = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.user = null;
      state.authenticated = false;
      state.loading = false;
    });

  }
})

export const { setUser } = userSelectorSlice.actions;

export default userSelectorSlice.reducer