import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store'; // Adjust the import path as necessary
import { setTheme } from '../store/features/theming/theme-selector-slice'; // Adjust the import path as necessary
import { ThemeConstants } from '../constants/theme-constants'; // Adjust the import path as necessary

export const useChangeTheme = () => {
  const dispatch = useDispatch();
  const themeSelector = useSelector((state: RootState) => state.themeSelector);

  const changeTheme = () => {
    if (themeSelector.theme === ThemeConstants.default) {
      dispatch(setTheme(ThemeConstants.pakFoods));
    } else {
      dispatch(setTheme(ThemeConstants.default));
    }
  };

  return changeTheme;
};
