import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { fetchApproval, updateApproval } from '../../store/features/ordering/approval/approval-slice';
import { ApplicationApproval } from '../../models/order/approval/application-approval';
import styled from 'styled-components';
import { ProductTitle } from '../../styles/shared/product';
import Image from '../../components/controls/image';
import Button from '../../components/controls/button';
import { FeatureCard, FeatureCardColumn, FeatureCardRow, FeatureCardSingleColumn, FeatureCardTitle } from '../../styles/shared/card';
import TextInput from '../../components/controls/text-input';
import { ICON_ID } from '../../components/icons/google-icon';
import { ApplicationApprovalStatus } from '../../models/order/application-approval-status';
import { showSuccess } from '../../components/notification/toastr-actions';
import { closeModal } from '../../store/features/modal/modal-slice';

interface ActionRequestProps {
    orderLineId: number
}

interface InlineFieldEdit {
    value: number,
    showEdit: boolean;
}

export default function ActionRequestModal({ orderLineId }: ActionRequestProps) {
    const [approval, setApproval] = useState<ApplicationApproval>()
    const [quantityEdit, setQuantityEdit] = useState<InlineFieldEdit>({
        value: 0, showEdit: false,
    });

    const [approvalText, setApprovalText] = useState("Approve")

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchApproval(orderLineId)).then((resposne) => {
            const approval = resposne.payload as ApplicationApproval;
            setApproval(approval);
            setQuantityEdit(prevState => ({
                ...prevState,
                value: approval.quantity
            }));
        })
    }, [orderLineId])


    const setFieldValue = (value: string, setFunction: React.Dispatch<React.SetStateAction<InlineFieldEdit>>) => {
        var newValue: number = parseFloat(value);
        setFunction(prevState => ({
            ...prevState,
            showEdit: prevState?.showEdit,
            value: newValue
        }));
    };

    const toggleFieldEdit = (value: number, setFunction: React.Dispatch<React.SetStateAction<InlineFieldEdit>>) => {
        setFunction(prevState => ({
            ...prevState,
            showEdit: !prevState.showEdit,
            value,
        }));
    };

    const approveRequest = () => {
        if (!approval) {
            return;
        }

        dispatch(updateApproval({ orderLineId: approval!.id, quantity: quantityEdit.value, approvalStatus: ApplicationApprovalStatus.Approved })).then(() => {
            dispatch(showSuccess("Request approved"));
            dispatch(closeModal());
        })
    }

    const rejectRequest = () => {
        if (!approval) {
            return;
        }
        dispatch(updateApproval({ orderLineId: approval.id, quantity: approval?.quantity, approvalStatus: ApplicationApprovalStatus.Rejected })).then(() => {
            dispatch(showSuccess("Request rejected"));
            dispatch(closeModal());
        })
    }

    useEffect(() => {
        if (quantityEdit.value != approval?.quantity) {
            setApprovalText("Approve & Update")
        } else {
            setApprovalText("Approve")
        }
    }, [quantityEdit])

    if (!approval) {
        return <></>
    }

    return (

        <Container>
            <ProductDetails>
                <ProductTitle id={`product_${approval.productId}`}>{approval.productName} ({approval.productSize})</ProductTitle>
                <ProductImage>
                    <Image image={approval.productImage}></Image>
                </ProductImage>
                <ProductDescription>
                    {approval.productDescription}
                </ProductDescription>
            </ProductDetails>
            <RequestedInformationContainer>
                <RequestedInformationRow>
                    <div>Order #{approval.orderId}</div>
                    <div> Requested By: {approval.username}</div>

                </RequestedInformationRow>
                <RequestedInformationRow>
                    <div> Branch: {approval.branchName}</div>
                    <div> Created: {new Date(approval.created).toLocaleDateString()} </div>

                </RequestedInformationRow>
            </RequestedInformationContainer>
            <FeatureCard>
                <FeatureCardTitle>
                    Requested
                </FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        {quantityEdit.showEdit ? (
                            <InputWrapper>
                                <TextInput type="number" id='txtQuantityInput' label="Quantity" value={quantityEdit.value.toString()} onChange={(value) => setFieldValue(value.target.value, setQuantityEdit)}></TextInput>
                                <Button id="btnQuantityClose" label='' icon={ICON_ID.Close} variant='text' onClick={() => toggleFieldEdit(approval.quantity, setQuantityEdit)}></Button>
                            </InputWrapper>

                        ) :
                            <>
                                <div>Quantity</div>
                                <DisplayWrapper>
                                    <span>{approval.quantity}</span>
                                    <Button variant="text" id="btnEditQuantity" label="" icon={ICON_ID.Edit} isPrimary={true} fullWidth={false} onClick={() => toggleFieldEdit(approval.quantity, setQuantityEdit)} />
                                </DisplayWrapper>

                            </>
                        }
                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>
            <FeatureCard>
                <FeatureCardTitle>
                    Notes
                </FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardSingleColumn>
                        {approval.notes}
                    </FeatureCardSingleColumn>
                </FeatureCardRow>
            </FeatureCard>
            <ButtonContainer>
                <Button id="btnReject" label="Reject" variant="outlined" isDanger onClick={rejectRequest} ></Button>
                <Button id="btnApprove" label={approvalText} variant="contained" onClick={approveRequest}></Button>
            </ButtonContainer>
        </Container>
    )
}

const Container = styled.div`
`

const ProductDetails = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;  
    border-bottom:3px solid ${(props) => props.theme.application.scheme.primary};  
    margin: 5px 10px;
    padding:5px 10px;
`

const ProductDescription = styled.div`    
    padding:10px;  
    font-size:1.1rem;
`;

const RequestedInformationContainer = styled.div`
    margin:25px 0px;
`

const RequestedInformationRow = styled.div`    
    display:flex;
    flex-wrap: wrap;
    margin: 15px 10px;

    div {
        flex:1;
    }
    
    div:last-of-type{
        text-align:end;
    }

`

const ProductImage = styled.div`
    margin-top:5px;
    img{
        width:150px;
        height:150px;
    }
`

const ButtonContainer = styled.div`
display:flex;
margin-top:10px;
    justify-content: flex-end;

    button:first-of-type{
        margin-right:10px;
    }
}
`

const InputWrapper = styled.div`
margin:0px 15px;
display:flex;
align-items: center;

`

const DisplayWrapper = styled.div`
    display:flex;
    font-weight:600;
    align-items:center;
    margin: 10px 0 0 55px;
`