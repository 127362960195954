import styled from "styled-components";

export const SmallSpacer = styled.div`
    height:8px;
`;

export const MediumSpacer = styled.div`
    height:15px;
`;

export const LargeSpacer = styled.div`
    height:22px;
`;