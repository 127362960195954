import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import TextInput from "./text-input";
import { ICON_ID } from "../icons/google-icon";

interface FileUploaderProps {
  buttonText: string;
  placeholder: string;
  acceptedFileTypes?: string;
  onFileSelected?: (file: File) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  buttonText,
  acceptedFileTypes,
  onFileSelected,
}) => {
  const [fileName, setFileName] = useState<string>("");
  const [fileInputKey, setFileInputKey] = useState<number>(0);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setFileName(file.name);
        if (onFileSelected) {
          onFileSelected(file);
        }
        // Update the key to force the input to reset
        setFileInputKey((prevKey) => prevKey + 1);
      } else {
        setFileName("");
      }
    },
    [onFileSelected]
  );

  return (
    <Container>
      <Input
        key={fileInputKey} // Use key to force reset
        type="file"
        id="fileInput"
        accept={acceptedFileTypes}
        onChange={handleFileChange}
      />
      <TextInputWrapper>
        <TextInput
          id="fileNameInput"
          label="Selected File"
          value={fileName}
          disabled={false}
        />
      </TextInputWrapper>
      <ButtonWrapper>
        <Button
          id="fileUploadButton"
          label={buttonText}
          isPrimary={true}
          variant="contained"
          icon={ICON_ID.CloudUpload}
          onClick={() => document.getElementById("fileInput")?.click()}
        />
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: end;
  gap: 16px;
`;

const TextInputWrapper = styled.div`
  flex: 1;
`;

const ButtonWrapper = styled.div`
  flex-shrink: 0;
  padding-bottom: 10px;
`;

const Input = styled.input`
  display: none;
`;

export default FileUploader;
