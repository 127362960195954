import React from 'react';
import styled from 'styled-components';
import { GoogleIcon, ICON_ID, ICON_SIZES } from './google-icon';

interface BasketIconProps {
  count: number | null;
  onClick: () => void;
}

export default function BasketIcon({ count, onClick }: BasketIconProps) {
  return (
    <BasketContainer onClick={onClick}>
      <GoogleIcon size={ICON_SIZES.Medium} id={ICON_ID.ShoppingBasket} />
      <Badge>{count}</Badge>
    </BasketContainer>
  );
}

const BasketContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const Badge = styled.span`
  position: absolute;
  top: -5px;
  right: -4px;
  background-color:  ${props => props.theme.application.scheme.secondary};
  color:  ${props => props.theme.application.scheme.contrast};
border-radius: 50%;
    width: 16px;
    height: 17px;
    text-align: center;
    font-size: 0.7rem;
    padding: 2px;
}
`;
