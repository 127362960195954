import { useEffect, useMemo } from "react";
import { useAppSelector } from "./useAppSelector"
import { useAppDispatch } from "./useAppDispatch";
import { fetchBasket, resetBasket } from "../store/features/ordering/basket/basket-slice";

export const useBasket = () => {
    const basket = useAppSelector((state => state.basket));
    const branch = useAppSelector((state => state.branch)).selectedBranch;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (basket.basket == null) {
            dispatch(fetchBasket(branch!.id));
        }
    }, [basket.basket])

    return basket.basket;
}