import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import BasketLayout from '../../pages/layouts/basket-layout';
import { ApplicationRole } from '../../models/authentication/application-roles';

export const BasketRoute = () => {
    const { initialized, loading, authenticated, role } = useAuth();
    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }

    if (role == ApplicationRole.Ordering) {
        return <Navigate to="/supplier" replace />;
    }

    return (
        <BasketLayout>
            <Outlet />
        </BasketLayout>
    )
};