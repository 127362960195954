import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchSuppliers } from '../../store/features/supplier/supplier-slice';
import styled from 'styled-components';
import TableView, { ColumnConfig } from '../../components/controls/table-view';
import Button from '../../components/controls/button';
import { TableContainer, TextContainer } from '../../styles/shared/table';
import { SmallSpacer } from '../../styles/shared/spacing';
import { ICON_ID } from '../../components/icons/google-icon';
import { openModal } from '../../store/features/modal/modal-slice';
import { ModalID } from '../../constants/modal-constants';
import { useNavigate } from 'react-router-dom';

export default function SupplierHome() {
    const suppliers = useAppSelector(state => state.supplier);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const fetchMoreData = () => {
        dispatch(fetchSuppliers())
    }

    const renderActionButton = (row: any) => (
        <Button variant="outlined" id={row.Name} label='View' isPrimary={true} fullWidth={false} onClick={() => navigate(`/supplier/${row.id}`)} />
    );

    const columnConfig: { [key: string]: ColumnConfig } = {
        id: {
            hidden: true,
        },
        name: {
            order: 1,
        },
        noOfProducts: {
            order: 2,
            name: "Products",
            render: (value: string) => (
                <TextContainer>
                    {value} products
                </TextContainer>
            )
        },
        addressLine1: {
            order: 3,
            name: "Address",
            render: (value: string, row: any) => (
                <TextContainer>
                    <div>
                        {value}
                    </div>
                    <div>
                        {row.addressLine2}
                    </div>
                    <div>
                        {row.addressLine3}
                    </div>
                    <div>
                        {row.city}
                    </div>
                    <div>
                        {row.postcode}
                    </div>
                </TextContainer>
            )
        },
        phone: {
            order: 4,
            name: "Contact",
            render: (value: string, row: any) => (
                <TextContainer>
                    <div><a href={`tel:${value}`}>{value}</a></div>
                    <SmallSpacer></SmallSpacer>
                    <div><a href={`mailto:${row.email}`}>{row.email}</a></div>
                </TextContainer>
            )
        },
        updated: {
            order: 5,
            render: (value: Date) => (
                <TextContainer>
                    {new Date(value).toLocaleDateString()} {new Date(value).toLocaleTimeString()}
                </TextContainer>
            )
        },
        email: {
            hidden: true,
        },
        addressLine2: {
            hidden: true
        },
        addressLine3: {
            hidden: true
        },
        city: {
            hidden: true
        },
        postcode: {
            hidden: true
        },
    };

    const openNewSupplier = () => {
        dispatch(openModal({
            id: ModalID.EditSupplierModal,
        }));
    }

    return (
        <SupplierContainer>
            <ActionBar>
                <div><Button id="createNewSupplier" onClick={openNewSupplier} label="Create new supplier" variant="contained" icon={ICON_ID.Add}></Button></div>

            </ActionBar>
            <TableContainer>
                <TableView
                    id="No suppliers"
                    emptyText='No suppliers'
                    fetchMoreData={fetchMoreData}
                    totalCount={suppliers.suppliers?.count ?? 0}
                    data={suppliers?.suppliers?.suppliers ?? []}
                    columnConfig={columnConfig}
                    actionButton={renderActionButton}
                    showHeaders={true}
                />
            </TableContainer>
        </SupplierContainer>
    )
}


const SupplierContainer = styled.div`
    height:95%;
    
`


const ActionBar = styled.div`
    display:flex;
    margin-bottom:20px;
    align-items: center;        

    div{
        margin-right:50px;
    }

    div.searchDiv{    
        flex:grow:1;
    }
`

