import React from "react";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";

interface BrandLogoProps {
  size: 'small' | 'medium' | 'large';
}

const BrandLogo: React.FC<BrandLogoProps> = ({ size }) => {
  const theme = useTheme();

  return <LogoImage src={theme.application.logoPath} alt="Brand Logo" size={size} />;
};

const LogoImage = styled.img<{ size: 'small' | 'medium' | 'large' }>`
  width: auto;

  height: ${props => {
    switch (props.size) {
      case 'small':
        return '20px';
      case 'medium':
        return '40px';
      case 'large':
        return '60px';
      default:
        return '40px'; // default to medium if size is not provided
    }
  }};
`;

export default BrandLogo;
