import React from 'react'
import { ApplicationDepartment } from '../../models/organisation/application-department'
import { GoogleIcon, ICON_ID } from '../icons/google-icon'
import { ActiveArea } from '../../styles/shared/page'
import { useNavigate } from 'react-router-dom'
import Button from '../controls/button'
import AnchorLink from '../controls/anchor-link'
import styled from 'styled-components'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { closeModal } from '../../store/features/modal/modal-slice'



export default function Back() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const onClick = () => {
        navigate(-1)
    }

    return (
        <ButtonWrapper>
            <Button variant="text" icon={ICON_ID.ArrowBack} label='Back' id="btnBack" isPrimary={false} onClick={onClick}></Button>
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled.div`

button {
    font-size:1rem;
    span{
    font-size:1.2rem;
    }
}
`

