import React from 'react';
import { LoadingButton } from '@mui/lab';
import { BackdropProps } from '@mui/material';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../icons/google-icon';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { getColour } from '../../styles/shared/colour';

interface ButtonInputProps {
    id: string;
    label: string;
    fullWidth?: boolean | false;
    isPrimary?: boolean | true;
    isDanger?: boolean | false;
    isWarning?: boolean | false;
    isSecondary?: boolean | false;
    isNeutral?: boolean | false;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    loading?: boolean | false;
    icon?: ICON_ID | null;
    variant: 'text' | 'outlined' | 'contained';
    disabled?: boolean | false;
    hidden?: boolean | false;
}


export default function Button(properties: ButtonInputProps) {
    const color = getColour(
        properties?.isDanger ?? false,
        false,
        properties.isWarning ?? false,
        false,
        properties?.isPrimary ?? false,
        properties.isSecondary ?? false,
        properties.isNeutral ?? false
    );


    return (
        <StyledButton
            id={properties.id}
            variant={properties.variant}
            fullWidth={properties.fullWidth}
            onClick={properties.onClick}
            disabled={properties.disabled}
            loading={properties.loading}
            hidden={properties.hidden}
            colour={color}
        >
            {properties.icon && (
                <GoogleIcon
                    id={properties.icon}
                    size={ICON_SIZES.Medium}
                    isDanger={properties.variant == "text" ? properties.isDanger : false}
                    isPrimary={properties.variant == "text" ? properties.isPrimary : false}
                    isContrast={properties.variant != "text"}
                />
            )}

            {properties.label}
        </StyledButton>
    );
}

interface ButtonStyleProps {
    colour: string,
    variant: string,
}

const StyledButton = styled(LoadingButton) <ButtonStyleProps>`
    &.MuiButtonBase-root{    
        color: ${({ variant, theme, colour }) =>
        variant !== 'contained' ? theme.application.scheme[colour] ?? theme.palette.primary.main : theme.application.scheme.contrast};
        border-color: ${({ variant, theme, colour }) =>
        variant !== 'contained' ? theme.application.scheme[colour] ?? theme.palette.primary.main : theme.application.scheme.contrast};



    &:disabled {
        background-color: ${({ theme }) => theme.application.scheme.neutral ?? theme.palette.action.disabledBackground};
        color: ${({ theme }) => theme.application.scheme.contrast ?? theme.palette.text.disabled};
        border-color: ${({ theme }) => theme.application.scheme.neutral ?? theme.palette.action.disabled};
    }

    }
`;
