import React from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import Header from '../../components/layout/header';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import styled from 'styled-components';
import Back from '../../components/layout/back';
import { ActiveArea, Content, ContentArea, HeaderArea, Main, Title, TitleArea, VerticalContent } from '../../styles/shared/page';
import ModalHandler from '../modals/modal-handler';
import Toastr from '../../components/notification/toastr';
import { useErrorDisplay } from '../../hooks/useErrorDisplay';
import Button from '../../components/controls/button';
import { createOrder } from '../../store/features/ordering/order-slice';
import { useNavigate } from 'react-router-dom';
import { ApplicationOrder } from '../../models/order/application-order';
import ConfirmModal from '../../components/notification/confirm-modal';
import { ApplicationBranch } from '../../models/organisation/application-branch';
import { setSelectedBranch } from '../../store/features/organisation/branch-slice';


interface LayoutProps {
  children: React.ReactNode;
}

const OwnerLayout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector((state => state.user)).user
  const branch = useAppSelector((state => state.branch)).selectedBranch
  const dispatch = useAppDispatch();

  useErrorDisplay();

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <ConfirmModal></ConfirmModal>
      <Header name={user?.name} team={user?.role} />
      <VerticalContent>
        <HeaderArea>
          <TitleArea>
            <Back></Back>
            <Title>
              Owner Portal
            </Title>
          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>
              {children}
            </ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default OwnerLayout;


