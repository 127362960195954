import styled from "styled-components";

export const TextContainer = styled.div`
    font-size:1rem;
    font-weight:400;
  
    span.bold
    {                  
         font-weight:600;
    }
`
export const SmallTextContainer = styled.div`
    font-size:0.8rem;
    font-weight:400;
  
    span.bold
    {                  
         font-weight:600;
    }
`
export const TableContainer = styled.div`
  box-shadow: ${props => props.theme.application.boxShadow}; 
  height:100%;
`