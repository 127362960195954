import React from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./default-theme";
import { useAppSelector } from "../../hooks/useAppSelector";
import { pakFoodsTheme } from "./pakfoods-theme";
import { ThemeConstants } from "../../constants/theme-constants";
import { ThemeProvider as MUIThemer, CssBaseline } from "@mui/material";

export type ThemeProviderProps = {
  children: React.ReactNode;
};

const ApplicationTheme = ({ children }: ThemeProviderProps) => {
  let theme;
  const selectedTheme = useAppSelector((state => state.themeSelector.theme))

  switch (selectedTheme) {
    case ThemeConstants.default:
      theme = defaultTheme
      break;
    case ThemeConstants.pakFoods:
      theme = pakFoodsTheme
      break;
    default:
      theme = defaultTheme
  }

  return (
    <MUIThemer theme={theme}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </MUIThemer>
  );
};

export default ApplicationTheme