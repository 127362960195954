import { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { getLoggedInUser } from '../../store/features/authentication/user-slice';
import Layout from '../../pages/layouts/layout';
import useAuth from '../../hooks/useAuth';
import { ApplicationRole } from '../../models/authentication/application-roles';

export const HomeRoute = () => {
    const dispatch = useAppDispatch();
    const { initialized, loading, authenticated, role } = useAuth();

    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }

    if (role == ApplicationRole.Ordering) {
        return <Navigate to="/supplier" replace />;
    }

    return (
        <Layout>
            <Outlet />
        </Layout>
    )
};