
export const getColour = (isDanger: boolean, isSuccess: boolean, isWarning: boolean, isContrast: boolean, isPrimary: boolean, isSecondary: boolean, isNeutral: boolean): string => {
    if (isNeutral) return "neutral";
    if (isSecondary) return "secondary";
    if (isDanger) return "danger";
    if (isSuccess) return "success";
    if (isWarning) return "warning";
    if (isContrast) return "contrast";
    if (isPrimary) return "primary";
    return "";
};