import React from 'react'
import { Button as MUIButton, TextField } from '@mui/material'
import styled from 'styled-components';

interface LinkButtonProps {
    id: string;
    label: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;    
}

export default function TextButton(properties:LinkButtonProps) {
    return (
        <MUIButton
            id={properties.id}         
            onClick={properties.onClick}                 
        >
            {properties.label}
        </MUIButton>
    )
}



