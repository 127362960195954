type AnyObject = { [key: string]: any };

export function mergeArraysByField<T extends AnyObject>(
    arr1: T[],
    arr2: T[],
    field: keyof T
): T[] {
    const mergedMap = new Map<any, T>();

    // Add all items from the first array to the map
    arr1.forEach(item => {
        mergedMap.set(item[field], { ...item });
    });

    // Merge items from the second array into the map
    arr2.forEach(item => {
        if (mergedMap.has(item[field])) {
            // Merge the existing item with the new item
            mergedMap.set(item[field], { ...mergedMap.get(item[field]), ...item });
        } else {
            // Add new item to the map
            mergedMap.set(item[field], { ...item });
        }
    });

    // Convert the map back to an array
    return Array.from(mergedMap.values());
}
