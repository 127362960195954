import TableView, {
  ColumnConfig,
} from "../../../components/controls/table-view";
import Button from "../../../components/controls/button";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { showSuccess } from "../../../components/notification/toastr-actions";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  setNewValue,
  toggleEdit,
  applyNewValues,
  resetProductState,
  bulkUploadProducts,
} from "../../../store/features/supplier/supplier-product-upload-slice";
import {
  ProductContainer,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../../styles/shared/product";
import { ICON_ID } from "../../../components/icons/google-icon";
import { currencyFormatter } from "../../../helpers/formatters";
import styled from "styled-components";
import CurrencyInput from "../../../components/controls/currency-input";
import { ModalID } from "../../../constants/modal-constants";
import { openModal } from "../../../store/features/modal/modal-slice";
import { removeProductByName } from "../../../store/features/supplier/supplier-product-upload-slice";
import TextInput from "../../../components/controls/text-input";
interface UploadSupplierProductProps {
  supplierId: number;
  handleUploadSuccess: () => void;
}
export default function UploadSupplierProducts({
  supplierId,
  handleUploadSuccess,
}: UploadSupplierProductProps) {
  const products = useAppSelector((state) => state.supplierProductUpload);
  const dispatch = useAppDispatch();
  const renderActionButton = (row: any) => (
    <Button
      variant="outlined"
      id={row.name}
      label="Remove"
      isDanger={true}
      fullWidth={false}
      onClick={() => handleRemoveProduct(row.name)}
    />
  );

  const handleUploadProducts = async () => {
    // Dispatch the formatted products to the Redux store
    const response = await dispatch(
      bulkUploadProducts({
        supplierId: supplierId,
        products: products?.products,
      })
    );
    // Update condition based on actual response structure
    if (response) {
      dispatch(showSuccess(`Products uploaded successfully!`));
      handleUploadSuccess();
      dispatch(resetProductState());
    }
  };

  const handleRemoveProduct = (productName: string) => {
    dispatch(removeProductByName(productName));
  };

  const handleCloseButtonClick = (
    name: string,
    field: "Price" | "Size",
    value: number | string
  ) => {
    dispatch(toggleEdit({ name, field }));
    dispatch(setNewValue({ name, field, value }));
  };

  const columnConfig: { [key: string]: ColumnConfig } = {
    newPrice: {
      hidden: true,
    },
    editPrice: {
      hidden: true,
    },
    editSize: {
      hidden: true,
    },
    newSize: {
      hidden: true,
    },
    name: {
      name: "Product",
      order: 1,
      colspan: 2,
      render: (value: string, row: any) => (
        <ProductContainer>
          <ProductNameContainer>
            <ProductTitle>{value}</ProductTitle>
            <div>{row.size}</div>
          </ProductNameContainer>
        </ProductContainer>
      ),
    },
    price: {
      order: 2,
      render: (value: number | null, row: any) => {
        if (row.editPrice) {
          return (
            <FieldContainer>
              <CurrencyInput
                value={row.newPrice}
                onChange={(e) =>
                  dispatch(
                    setNewValue({
                      name: row.name,
                      field: "Price",
                      value: parseFloat(e.target.value),
                    })
                  )
                }
              ></CurrencyInput>
              <VerticalIcons>
                <Button
                  variant="text"
                  id={`btnSave${row.name}`}
                  label=""
                  icon={ICON_ID.Save}
                  isPrimary={true}
                  fullWidth={false}
                  onClick={() =>
                    dispatch(
                      applyNewValues({ names: row.name, field: "Price" })
                    )
                  }
                />
                <Button
                  variant="text"
                  id={`btnClose${row.name}`}
                  label=""
                  icon={ICON_ID.Close}
                  isPrimary={true}
                  fullWidth={false}
                  onClick={() =>
                    handleCloseButtonClick(row.name, "Price", row.price)
                  }
                />
              </VerticalIcons>
            </FieldContainer>
          );
        }
        return (
          <EditButtonContainer>
            <TextContainer>
              {value ? currencyFormatter.format(value) : <>Not set</>}
            </TextContainer>
            <EditButton
              variant="text"
              id={row.name}
              label=""
              icon={ICON_ID.Edit}
              isPrimary={true}
              fullWidth={false}
              onClick={() =>
                dispatch(toggleEdit({ name: row.name, field: "Price" }))
              }
            />
          </EditButtonContainer>
        );
      },
    },
    size: {
      order: 3,
      render: (value: number | null, row: any) => {
        if (row.editSize) {
          return (
            <FieldContainer>
              <TextInput
                label="Size"
                id="sizeInput"
                value={row.newSize}
                onChange={(e) =>
                  dispatch(
                    setNewValue({
                      name: row.name,
                      field: "Size",
                      value: e.target.value,
                    })
                  )
                }
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              // Handle input changes
              />
              <VerticalIcons>
                <Button
                  variant="text"
                  id={`btnSave${row.name}`}
                  label=""
                  icon={ICON_ID.Save}
                  isPrimary={true}
                  fullWidth={false}
                  onClick={() =>
                    dispatch(applyNewValues({ names: row.name, field: "Size" }))
                  }
                />
                <Button
                  variant="text"
                  id={`btnClose${row.name}`}
                  label=""
                  icon={ICON_ID.Close}
                  isPrimary={true}
                  fullWidth={false}
                  onClick={() =>
                    handleCloseButtonClick(row.name, "Size", row.size)
                  }
                />
              </VerticalIcons>
            </FieldContainer>
          );
        }
        return (
          <EditButtonContainer>
            <TextContainer>
              {value ? `${value}` : <>Not set</>}
            </TextContainer>
            <EditButton
              variant="text"
              id={row.name}
              label=""
              icon={ICON_ID.Edit}
              isPrimary={true}
              fullWidth={false}
              onClick={() =>
                dispatch(toggleEdit({ name: row.name, field: "Size" }))
              }
            />
          </EditButtonContainer>
        );
      },
    },
  };
  return (
    <>
      <div>
        <Button
          variant="contained"
          icon={ICON_ID.Upload}
          label="Upload Products by CSV"
          id="btnUploadProducts"
          onClick={() =>
            dispatch(
              openModal({
                id: ModalID.UploadSupplierProduct, // Modal for CSV upload
                data: { id: supplierId },
              })
            )
          }
        />
      </div>
      <TableContainer>
        <TableView
          id="tblUploadSupplieProducts"
          emptyText="Upload Products by CSV"
          totalCount={products?.products?.length ?? 0}
          data={products.products ?? []}
          columnConfig={columnConfig}
          actionButton={renderActionButton}
          showHeaders={true}
        />
      </TableContainer>
      {products.products?.length > 0 && (
        <ButtonContainer>
          <Button
            variant="contained"
            label="Upload Products"
            id="btnUploadProducts"
            onClick={handleUploadProducts}
          />
        </ButtonContainer>
      )}
    </>
  );
}

const TableContainer = styled.div`
  flex: 0 0 60vh;
  min-height: 0; /* workaround for scrolling fix when flipping to column flex */
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const VerticalIcons = styled.div`
  display: flex;
  flex-direction: column;

  button {
    span {
      font-size: 1.1rem;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const EditButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TextContainer = styled.span`
  flex-grow: 1;
`;

const EditButton = styled(Button)`
  flex-shrink: 0;
`;
