import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomeTabConstants } from "../../../constants/home-tab-constants";

interface HomeState {
    tab: string
}

const initialState: HomeState = {
    tab: HomeTabConstants.activeOrders,
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
    },
});

export const { setTab } = homeSlice.actions;
export default homeSlice.reducer;
