import { Middleware } from "@reduxjs/toolkit";
import { setError } from "../features/notification/error-slice"

const errorHandlingMiddleware: Middleware = ({ dispatch }) => (next) => (action: any) => {
    if (action.type.endsWith('rejected')) {
        dispatch(setError(action.payload));
    }
    return next(action);
};

export default errorHandlingMiddleware;
