import { useEffect } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import TableView, { ColumnConfig } from '../../components/controls/table-view';
import { ProductContainer, ProductImage, ProductNameContainer, ProductTitle } from '../../styles/shared/product';
import styled from 'styled-components';
import { fetchOrderLines } from '../../store/features/ordering/order-slice';
import Image from '../../components/controls/image';
import { ApplicationApprovalStatus } from '../../models/order/application-approval-status';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../../components/icons/google-icon';
import { SmallTextContainer, TableContainer, TextContainer } from '../../styles/shared/table';



export default function OrderView() {
    const order = useAppSelector(state => state.order);
    const dispatch = useAppDispatch();

    const fetchMoreData = () => {
        if (order.order?.id) {
            dispatch(fetchOrderLines(order.order.id))
        }
    }

    useEffect(() => {
        fetchMoreData();
    }, [order.order?.id])


    const columnConfig: { [key: string]: ColumnConfig } = {
        id: {
            hidden: true
        },
        productId: {
            hidden: true,
        },
        productDescription: {
            hidden: true
        },
        productSize: {
            hidden: true
        },
        productImage: {
            hidden: true
        },
        plu: {
            hidden: true,
        },
        productName: {
            order: 1,
            name: "Product",
            colspan: 3,
            render: (value: number, row: any) => (
                <ProductContainer>
                    {row.approvalStatus == ApplicationApprovalStatus.AwaitingApproval &&
                        <GoogleIcon tooltip='Awaiting approval' id={ICON_ID.Error} isWarning size={ICON_SIZES.Large}></GoogleIcon>
                    }

                    {row.approvalStatus == ApplicationApprovalStatus.Approved &&
                        <GoogleIcon tooltip='Approved' id={ICON_ID.CheckCircle} size={ICON_SIZES.Large} isSuccess></GoogleIcon>
                    }

                    {row.approvalStatus == ApplicationApprovalStatus.Rejected &&
                        <GoogleIcon tooltip='Rejected' id={ICON_ID.Close} size={ICON_SIZES.Large} isDanger></GoogleIcon>
                    }

                    <ProductImage>
                        <Image image={row.productImage}></Image>
                    </ProductImage>
                    <ProductNameContainer>
                        <ProductTitle>{value}</ProductTitle>
                        <div>{row.productSize} - {row.productDescription}</div>
                        <SmallTextContainer>Plu: {row.plu}</SmallTextContainer>
                    </ProductNameContainer>
                </ProductContainer>
            )
        },
        quantity: {
            order: 3,
            render: (value: number, row: any) => (
                <TextContainer>
                    Qty: {value}
                </TextContainer>
            )
        },
        notes: {
            order: 4,
            render: (value: string) => (
                <SmallTextContainer>
                    {value}
                </SmallTextContainer>
            )
        },
        approvalStatus: {
            hidden: true,
        }
    };

    return (
        <OrderContainer>
            <TableContainer>
                <TableView
                    id="ordersTable"
                    emptyText='Hmmmm, you seem to have no items in your order, this must be an error'
                    fetchMoreData={fetchMoreData}
                    totalCount={order.orderLines?.count ?? 0}
                    data={order.orderLines?.orderLines ?? []}
                    columnConfig={columnConfig}
                    preLoadedData={true}
                    showHeaders={true}
                />
            </TableContainer>
        </OrderContainer>
    )
}
const OrderContainer = styled.div`
width:90%;
margin:auto;
height: 100%;
`
