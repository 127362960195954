import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { ApplicationError } from "../../../../models/errors/application-error";
import { ApplicationBasket } from "../../../../models/order/basket/application-basket";
import { ApplicationBasketItemList } from "../../../../models/order/basket/application-basket-item-list";
import BasketService from "../../../../services/order/basket-service";
import { ApplicationBasketItem } from "../../../../models/order/basket/application-basket-item";
import { showSuccess } from "../../../../components/notification/toastr-actions";
import { mergeArraysByField } from "../../../../helpers/array-helper";
import { ApplicationApprovalStatus } from "../../../../models/order/application-approval-status";

interface BasketState {
    basket: ApplicationBasket | null
    basketItems: ApplicationBasketItemList | null
}

const initialState: BasketState = {
    basket: null,
    basketItems: {
        items: [],
        count: -1,
        currentPosition: 0
    },
};

interface UpdateBasketItemResponse {
    basketItemId: number
    value: number
}

export const fetchBasket = createAsyncThunk<ApplicationBasket, number, { state: RootState }>(
    'basket/fetch',
    async (branchId, { rejectWithValue }) => {
        const basketService = new BasketService();
        try {
            return await basketService.getUserBasket(branchId);
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const fetchBasketItems = createAsyncThunk<ApplicationBasketItemList | null, number, { state: RootState }>(
    'basketItems/fetch',
    async (basketId, { getState, rejectWithValue }) => {
        const state = getState();
        const basketService = new BasketService();
        try {
            var currentPosition: number = state.basket.basketItems?.currentPosition ?? 0;
            var fetchNext: number = 15;
            var count: number = state.basket.basketItems?.count ?? -1;
            if (currentPosition != count) {
                return await basketService.getBasketItems(basketId, currentPosition, fetchNext)
            }
            return null;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const fetchByProductId = createAsyncThunk<ApplicationBasketItem | null, { basketId: number, productId: number }, { state: RootState }>(
    'basketItems/product/fetch',
    async ({ basketId, productId }, { getState, rejectWithValue }) => {
        const state = getState();
        const basketService = new BasketService();
        try {
            return await basketService.getByProductId(basketId, productId)
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const insertBasketItem = createAsyncThunk<
    ApplicationBasketItem,
    { basketId: number, basketItem: ApplicationBasketItem },
    { state: RootState }>(
        'basketItems/insert',
        async ({ basketId, basketItem }, { getState, rejectWithValue }) => {
            const basketService = new BasketService();
            try {
                basketItem.id = await basketService.insertBasketItem(basketId, basketItem)
                return basketItem;
            } catch (error: any) {
                const apiError = ApplicationError.handleApiError(error, {});
                return rejectWithValue(apiError);
            }
        }
    );

export const updateBasketItemStock = createAsyncThunk<
    UpdateBasketItemResponse,
    { basketItemId: number, currentStock: number },
    { state: RootState }>(
        'basketItems/updateStock',
        async ({ basketItemId, currentStock }, { dispatch, getState, rejectWithValue }) => {
            const basketService = new BasketService();
            try {

                const state = getState().basket;
                var basketItem = state.basketItems?.items.find(x => x.id == basketItemId);

                if (!basketItem) {
                    throw new ApplicationError("No basket item found");
                }

                await basketService.updateBasketItem(basketItemId, basketItem.quantity, currentStock, basketItem.notes)
                dispatch(showSuccess(`${basketItem.productName} stock level updated to ${currentStock}`));
                return { basketItemId: basketItemId, value: currentStock };
            } catch (error: any) {
                const apiError = ApplicationError.handleApiError(error, {});
                return rejectWithValue(apiError);
            }
        }
    );

export const updateBasketItemQuantity = createAsyncThunk<
    UpdateBasketItemResponse,
    { basketItemId: number, quantity: number },
    { state: RootState }>(
        'basketItems/updateQuantity',
        async ({ basketItemId, quantity }, { dispatch, getState, rejectWithValue }) => {
            const basketService = new BasketService();
            try {

                const state = getState().basket;
                var basketItem = state.basketItems?.items.find(x => x.id == basketItemId);

                if (!basketItem) {
                    throw new ApplicationError("No basket item found");
                }

                await basketService.updateBasketItem(basketItemId, quantity, basketItem.currentStock, basketItem.notes)

                dispatch(showSuccess(`${basketItem.productName} quantity updated to ${quantity}`));
                return { basketItemId: basketItemId, value: quantity };
            } catch (error: any) {
                const apiError = ApplicationError.handleApiError(error, {});
                return rejectWithValue(apiError);
            }
        }
    );

export const deleteBasketItem = createAsyncThunk<
    number,
    number,
    { state: RootState }>(
        'basketItems/remove',
        async (basketItemId, { dispatch, getState, rejectWithValue }) => {
            const basketService = new BasketService();
            try {

                const state = getState().basket;
                var basketItem = state.basketItems?.items.find(x => x.id == basketItemId);

                if (!basketItem) {
                    throw new ApplicationError("No basket item found");
                }

                await basketService.deleteBasketItem(basketItemId);
                dispatch(showSuccess(`${basketItem.productName} removed from basket`));

                return basketItemId;
            } catch (error: any) {
                const apiError = ApplicationError.handleApiError(error, {});
                return rejectWithValue(apiError);
            }
        }
    );


const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        resetBasket: (state) => {
            state.basket = initialState.basket;
            state.basketItems = initialState.basketItems;
        },
        updateApprovalStatus: (state, action: PayloadAction<{ basketItemId: number; approvalStatus: ApplicationApprovalStatus }>) => {
            const { basketItemId, approvalStatus } = action.payload;
            updateBasketItemProperty(state, basketItemId, 'approvalStatus', approvalStatus);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBasket.fulfilled, (state, action: PayloadAction<ApplicationBasket>) => {
                state.basket = action.payload;
            })
            .addCase(fetchBasketItems.fulfilled, handleFetchBasketItemsFulfilled)
            .addCase(insertBasketItem.fulfilled, (state, action: PayloadAction<ApplicationBasketItem>) => {
                state.basketItems?.items.push(action.payload);
                state.basket!.itemCount += 1;
            })
            .addCase(updateBasketItemStock.fulfilled, (state, action: PayloadAction<{ basketItemId: number; value: number }>) => {
                updateBasketItemProperty(state, action.payload.basketItemId, 'currentStock', action.payload.value);
            })
            .addCase(updateBasketItemQuantity.fulfilled, (state, action: PayloadAction<{ basketItemId: number; value: number }>) => {
                updateBasketItemProperty(state, action.payload.basketItemId, 'quantity', action.payload.value);
            })
            .addCase(deleteBasketItem.fulfilled, (state, action: PayloadAction<number>) => {
                if (state.basketItems) {
                    const index = state.basketItems.items.findIndex(item => item.id === action.payload);

                    if (index !== -1) {
                        state.basketItems.items.splice(index, 1);
                        state.basketItems.count -= 1;
                        state.basketItems.currentPosition -= 1;
                        state.basket!.itemCount -= 1;
                    }
                }
            });
    },
});

const handleFetchBasketItemsFulfilled = (
    state: BasketState,
    action: PayloadAction<ApplicationBasketItemList | null>
) => {
    if (action.payload) {
        if (state?.basketItems?.items) {
            state.basketItems.items = mergeArraysByField(state.basketItems.items, action.payload.items, "productId");
            state.basketItems.currentPosition = action.payload.currentPosition;
            state.basketItems.count = action.payload.count;
        } else {
            state.basketItems = action.payload;
        }
    }
};

const updateBasketItemProperty = <K extends keyof ApplicationBasketItem>(
    state: BasketState,
    basketItemId: number,
    property: K,
    value: ApplicationBasketItem[K]
) => {
    const basketItem = state.basketItems?.items.find(item => item.id === basketItemId);
    if (basketItem) {
        basketItem[property] = value;
    }
};

export const { resetBasket, updateApprovalStatus } = basketSlice.actions;

export default basketSlice.reducer;
