import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { getLoggedInUser } from '../../store/features/authentication/user-slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

export const GuestRoute = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state: RootState) => state.user);

    useEffect(() => {
        dispatch(getLoggedInUser())
    }, [])

    if (user.loading) {
        return <div>Loading....</div>
    }
    return !user.authenticated ? <Outlet /> : <Navigate to="/" replace />;

};

