import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../controls/button';
import { useAppSelector } from '../../hooks/useAppSelector';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../icons/google-icon';
import { Alert } from '@mui/material';


const ConfirmModal: React.FC = () => {
  const confirm = useAppSelector(state => state.confirm);

  useEffect(() => {

  }, [confirm])
  if (!confirm.show) {
    return null;
  }

  return (
    <ModalOverlay>
      <Modal>
        <ModalHeader>
          <GoogleIcon id={ICON_ID.Error} isWarning size={ICON_SIZES.Large}></GoogleIcon>
          {confirm.title}
        </ModalHeader>
        <ModalBody>
          <Alert severity="warning">{confirm.message}</Alert>
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" isDanger={true} id="btnModalCancel" label="Cancel" onClick={confirm.data?.onCancel}></Button>
          <Button variant="contained" id="btnModalConfirm" label="Confirm" onClick={confirm.data?.onConfirm}></Button>

        </ModalFooter>
      </Modal>
    </ModalOverlay>
  );
};

export default ConfirmModal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
z-index:4;
`;

const Modal = styled.div`
  background-color: ${({ theme }) => theme.application.background};
  border-radius: 8px;
  max-width: 500px;
  box-shadow: ${props => props.theme.application.boxShadow}; 
`;

const ModalHeader = styled.div`
  padding: 10px 16px;
  text-align:center;
  border-radius: 8px 8px 0px 0px;
  background: ${({ theme }) => theme.application.background};    
  border-bottom: 2px solid  ${({ theme }) => theme.application.scheme.warning};
  font-size:1.1rem;
  font-weight:500;
  text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

`;

const ModalBody = styled.div`
  padding: 16px;  
`;

const ModalFooter = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

