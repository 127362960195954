import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationBranch } from "../../../models/organisation/application-branch";

interface BranchState {
    selectedBranch: ApplicationBranch | null;
}

const initialState: BranchState = {
    selectedBranch: null,
};


const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        setSelectedBranch: (state, action: PayloadAction<ApplicationBranch | null>) => {
            state.selectedBranch = action.payload;
        },
    },

});

export const { setSelectedBranch } = branchSlice.actions;

export default branchSlice.reducer;
