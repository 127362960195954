import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationApprovalList } from "../../../../models/order/approval/application-approval-list";
import ApprovalService from "../../../../services/order/approval-service";
import { ApplicationSort } from "../../../../models/shared/application-sort";
import { RootState } from "../../../store";
import { ApplicationError } from "../../../../models/errors/application-error";
import { ApplicationApproval } from "../../../../models/order/approval/application-approval";
import { ApplicationApprovalStatus } from "../../../../models/order/application-approval-status";


export const fetchApprovals = createAsyncThunk<ApplicationApprovalList | null, void, { state: RootState }>(
    'approval/get',
    async (_, { getState, rejectWithValue }) => {

        const state = getState();
        const approvalService = new ApprovalService();

        try {

            var currentPosition: number = state.approval?.approvals?.currentPosition ?? 0;
            var fetchNext: number = 15;
            var count: number = state.approval.approvals?.count ?? -1;

            if (currentPosition != count) {
                return await approvalService.get(currentPosition, fetchNext, state.approval?.sort)
            }
            return null;
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const fetchApproval = createAsyncThunk<ApplicationApproval | null, number, { state: RootState }>(
    'approval/view',
    async (orderLineId, { rejectWithValue }) => {
        const approvalService = new ApprovalService();
        try {
            return await approvalService.getApproval(orderLineId)
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const updateApproval = createAsyncThunk<number, { orderLineId: number, quantity: number, approvalStatus: ApplicationApprovalStatus }, { state: RootState }>(
    'approval/update',
    async ({ orderLineId, quantity, approvalStatus }, { rejectWithValue }) => {
        const approvalService = new ApprovalService();
        try {
            await approvalService.updateApproval(orderLineId, quantity, approvalStatus);
            return orderLineId;
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


interface ApprovalState {
    approvals: ApplicationApprovalList | null
    branchId: number,
    sort: ApplicationSort,
    loading: boolean,
}

const initialState: ApprovalState = {
    approvals: null,
    loading: false,
    branchId: 0,
    sort: ApplicationSort.Desc,
};

const approvalSlice = createSlice({
    name: 'approvals',
    initialState,
    reducers: {
        resetApprovals: (state) => {
            state.approvals = initialState.approvals;
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchApprovals.fulfilled, (state, action: PayloadAction<ApplicationApprovalList | null>) => {
                if (action.payload) {
                    if (state.approvals?.approvals) {
                        state.approvals.approvals = state.approvals.approvals.concat(action.payload!.approvals);
                        state.approvals.currentPosition = action.payload!.currentPosition
                        state.approvals.count = action.payload!.count;
                    } else {
                        state.approvals = action.payload;
                    }
                }
            })
            .addCase(updateApproval.fulfilled, (state, action: PayloadAction<number>) => {
                if (state.approvals) {
                    const index = state.approvals.approvals.findIndex(item => item.id === action.payload);

                    if (index !== -1) {
                        state.approvals.approvals.splice(index, 1);
                        console.info(state.approvals.currentPosition, state.approvals.count);
                        state.approvals.currentPosition -= 1;
                        state.approvals.count -= 1;
                    }
                }
            })
    },
});


export const { resetApprovals } = approvalSlice.actions;
export default approvalSlice.reducer;
