import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import ProductService from "../../../services/product/product-service";
import { ApplicationCategoryList } from "../../../models/product/application-category-list";
import { ApplicationProductDictionary, ApplicationProductList } from "../../../models/product/application-product-list";
import { ApplicationCategory } from "../../../models/product/application-category";
import { ApplicationProductDetail } from "../../../models/product/application-product-detail";
import { ApplicationBestSupplierProduct } from "../../../models/product/application-best-supplier-product";

export const fetchCategoriesByDepartment = createAsyncThunk<ApplicationCategoryList | null, number, { state: RootState }>(
    'products/categories/fetch',
    async (departmentId, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            var currentPosition: number = state.product.categories?.currentPosition ?? 0;
            var fetchNext: number = 10;
            var count: number = state.product.categories?.count ?? -1;
            if (currentPosition != count) {
                return await productService.getCategoriesByDepartment(departmentId, currentPosition, fetchNext)
            }
            return null;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const fetchProducts = createAsyncThunk<ApplicationProductList | null, number, { state: RootState }>(
    'products/fetch',
    async (categoryId, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            var currentPosition: number = state.product.products[categoryId]?.currentPosition ?? 0;
            var fetchNext: number = 70;
            var count: number = state.product.products[categoryId]?.count ?? -1;

            if (currentPosition != count) {
                // change to just fetch all, but left code in here if we need to infitite scroll products in future. 
                return await productService.getProducts(state.branch.selectedBranch!.id, categoryId, currentPosition, fetchNext, true);
            }

            return null;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const searchProducts = createAsyncThunk<ApplicationProductList | null, { departmentId: number, searchText: string }, { state: RootState }>(
    'products/search',
    async ({ departmentId, searchText }, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            var currentPosition: number = state.product.searchResults?.currentPosition ?? 0;
            var fetchNext: number = 70;
            var count: number = state.product.searchResults?.count ?? -1;

            if (currentPosition != count) {
                return await productService.searchProducts(state.branch.selectedBranch!.id, departmentId, searchText, currentPosition, fetchNext, false);
            }

            return null;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const fetchProduct = createAsyncThunk<ApplicationProductDetail | null, number, { state: RootState }>(
    'product/fetch',
    async (productId, { getState, rejectWithValue }) => {
        const state = getState()
        const productService = new ProductService();
        try {
            return await productService.getProduct(productId, state.branch.selectedBranch!.id);
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const fetchSupplierProduct = createAsyncThunk<ApplicationBestSupplierProduct | null, number, { state: RootState }>(
    'product/supplier/fetch',
    async (productId, { getState, rejectWithValue }) => {
        const state = getState()
        const productService = new ProductService();
        try {
            return await productService.getBestSupplierProduct(productId);
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const updateBranchProduct = createAsyncThunk<{ categoryId: number, productId: number, active: boolean }, { categoryId: number, productId: number, active: boolean }, { state: RootState }>(
    'product/branchProduct/update',
    async ({ categoryId, productId, active }, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            await productService.upsertBranchProduct(state.branch.selectedBranch!.id, productId, active)
            return { productId, active, categoryId };
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


interface SetLoadingProductPayload {
    categoryId: number;
    loading: boolean;
}

interface ProductState {
    categories: ApplicationCategoryList | null
    products: ApplicationProductDictionary
    searchString: string
    searchResults: ApplicationProductList | null
    searchLoading: boolean
}

const initialState: ProductState = {
    categories: null,
    products: {},
    searchString: "",
    searchResults: null,
    searchLoading: false,
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setSearchString: (state, action: PayloadAction<string>) => {
            state.searchString = action.payload;
            state.searchResults = initialState.searchResults;
        },
        resetProductPage: (state) => {
            state.categories = null;
            state.products = {};
        },
        toggleExpand: (state, action: PayloadAction<ApplicationCategory>) => {
            var category = state.categories?.categories.find(x => x.id == action.payload.id);
            if (category) {
                category.expand = !category.expand
            }
        },
        setLoadingProduct(state, action: PayloadAction<SetLoadingProductPayload>) {
            const { categoryId, loading } = action.payload
            if (state.products[categoryId]) {
                state.products[categoryId].loading = loading;
            }
        },
        setLoadingCategory(state, action: PayloadAction<boolean>) {
            if (state.categories) {
                state.categories.loading = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesByDepartment.fulfilled, handleFetchCategoriesFulfilled)
            .addCase(fetchProducts.fulfilled, handleFetchProductsFulfilled)
            .addCase(searchProducts.fulfilled, handleSearchProductsFulfilled)
            .addCase(searchProducts.pending, (state) => {
                state.searchLoading = true;
            })
            .addCase(searchProducts.rejected, (state) => {
                state.searchLoading = false;
            })
            .addCase(updateBranchProduct.fulfilled, handleUpdateBranchProduct)
    },
});

const handleUpdateBranchProduct = (
    state: ProductState,
    action: PayloadAction<{ categoryId: number, productId: number, active: boolean }>
) => {
    const { categoryId, productId, active } = action.payload;

    if (!active) {
        // Function to remove a product by its ID
        const removeProductById = (products: ApplicationProductList, productId: number) => {
            const productIndex = products.products.findIndex(x => x.id === productId);
            if (productIndex !== -1) {
                products.products.splice(productIndex, 1);
            }
        };

        // if category is equal to -1 it came from the search results
        if (categoryId === -1) {

            for (const key in state.products) {
                if (state.products.hasOwnProperty(key)) {
                    const categoryProducts = state.products[+key];
                    if (categoryProducts) {
                        removeProductById(categoryProducts, productId);
                    }
                }
            }
            if (state.searchResults) {
                removeProductById(state.searchResults, productId);
                state.searchResults.count -= 1;
            }
        } else {
            // Remove from specific category
            const categoryProducts = state.products[categoryId];
            if (categoryProducts) {
                removeProductById(categoryProducts, productId);
            }
        }
    }
};


const handleFetchCategoriesFulfilled = (
    state: ProductState,
    action: PayloadAction<ApplicationCategoryList | null>
) => {
    if (action.payload) {
        if (state.categories?.categories) {
            state.categories.categories = state.categories.categories.concat(action.payload.categories);
            state.categories.currentPosition = action.payload.currentPosition;
            state.categories.count = action.payload.count;
        } else {
            state.categories = action.payload;
        }
    }
};

const handleSearchProductsFulfilled = (
    state: ProductState,
    action: PayloadAction<ApplicationProductList | null>
) => {
    if (action.payload) {
        const { products, currentPosition, count } = action.payload;
        if (state.searchResults) {
            state.searchResults.products = state.searchResults.products.concat(products);
            state.searchResults.currentPosition = currentPosition;
            state.searchResults.count = count;
        } else {
            state.searchResults = action.payload;
        }
    }
    state.searchLoading = false;
};
const handleFetchProductsFulfilled = (
    state: ProductState,
    action: PayloadAction<ApplicationProductList | null>
) => {
    if (action.payload) {
        const { categoryId, products, currentPosition, count } = action.payload;
        if (state.products[categoryId]) {
            state.products[categoryId].products = state.products[categoryId].products.concat(products);
            state.products[categoryId].currentPosition = currentPosition;
            state.products[categoryId].count = count;
        } else {
            state.products[categoryId] = action.payload;
        }
    }
};

export const { toggleExpand, setLoadingProduct, setLoadingCategory, resetProductPage, setSearchString } = productSlice.actions;
export default productSlice.reducer;
