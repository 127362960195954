import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ThemeState {
    theme: string
}

const initialState: ThemeState = {
    theme: "default"
}

export const themeSelectorSlice = createSlice({
    name: 'themeSelector',
    initialState,
    reducers: {        
        setTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        }
    }
})

export const { setTheme } = themeSelectorSlice.actions;

export default themeSelectorSlice.reducer