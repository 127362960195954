import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationSupplierList } from "../../../models/supplier/application-supplier-list";
import { ApplicationError } from "../../../models/errors/application-error";
import SupplierService from "../../../services/supplier/supplier-service";
import { ApplicationSupplierDetail } from "../../../models/supplier/application-supplier-detail";
import { ApplicationSupplierHeader } from "../../../models/supplier/application-supplier-header";


export const fetchSuppliers = createAsyncThunk<ApplicationSupplierList | null, void, { state: RootState }>(
    'suppliers/get',
    async (_, { getState, rejectWithValue }) => {

        const state = getState();
        const supplierServic = new SupplierService();

        try {

            var currentPosition: number = state.supplier?.suppliers?.currentPosition ?? 0;
            var fetchNext: number = 15;
            var count: number = state.supplier.suppliers?.count ?? -1;

            if (currentPosition != count) {
                return await supplierServic.getSuppliers(currentPosition, fetchNext, false)
            }
            return null;
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const insertSupplier = createAsyncThunk<ApplicationSupplierDetail | null, ApplicationSupplierDetail, { state: RootState }>(
    'suppliers/insert',
    async (supplier, { rejectWithValue }) => {
        const supplierService = new SupplierService();

        try {
            return await supplierService.insertSupplier(supplier);
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const updateSupplier = createAsyncThunk<ApplicationSupplierDetail | null, ApplicationSupplierDetail, { state: RootState }>(
    'suppliers/update',
    async (supplier, { rejectWithValue }) => {
        const supplierService = new SupplierService();

        try {
            await supplierService.updateSupplier(supplier)

            return supplier;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const fetchSupplier = createAsyncThunk<ApplicationSupplierDetail | null, number, { state: RootState }>(
    'suppliers/getSingle',
    async (supplierId, { rejectWithValue }) => {
        const supplierService = new SupplierService();
        try {

            return await supplierService.getSupplier(supplierId);

        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

interface SupplierState {
    suppliers: ApplicationSupplierList | null
    selectedSupplier: ApplicationSupplierDetail | null,
    loading: boolean,
}

const initialState: SupplierState = {
    suppliers: null,
    selectedSupplier: null,
    loading: false,
};

const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        resetSelectedSupplier: (state) => {
            state.selectedSupplier = initialState.selectedSupplier;
        },
        resetSuppliers: (state) => {
            state.suppliers = initialState.suppliers;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSuppliers.fulfilled, (state, action: PayloadAction<ApplicationSupplierList | null>) => {
                if (action.payload) {
                    if (state.suppliers?.suppliers) {
                        state.suppliers.suppliers = state.suppliers.suppliers.concat(action.payload!.suppliers);
                        state.suppliers.currentPosition = action.payload!.currentPosition
                        state.suppliers.count = action.payload!.count;
                    } else {
                        state.suppliers = action.payload;
                    }
                }
            })
            .addCase(insertSupplier.fulfilled, (state, action: PayloadAction<ApplicationSupplierDetail | null>) => {
                if (action.payload) {
                    if (state.suppliers?.suppliers) {
                        state.suppliers.suppliers.unshift(mapToSupplierHeader(action.payload))
                        state.suppliers.count += 1
                    }
                }
            })
            .addCase(fetchSupplier.fulfilled, (state, action: PayloadAction<ApplicationSupplierDetail | null>) => {
                if (action.payload) {
                    state.selectedSupplier = action.payload;
                }
            })
            .addCase(updateSupplier.fulfilled, (state, action: PayloadAction<ApplicationSupplierDetail | null>) => {
                if (action.payload) {
                    const updatedModel = action.payload;

                    if (state.selectedSupplier?.id == updatedModel.id) {
                        state.selectedSupplier = updatedModel;
                    }
                    if (state.suppliers?.suppliers) {

                        state.suppliers.suppliers = state.suppliers.suppliers.map(supplier =>
                            supplier.id === updatedModel.id ?
                                mapToSupplierHeader(updatedModel) : supplier
                        );
                    }
                }
            })
    },
});

const mapToSupplierHeader = (model: ApplicationSupplierDetail): ApplicationSupplierHeader => {
    return {
        id: model.id,
        name: model.name,
        noOfProducts: model.noOfProducts,
        addressLine1: model.addressLine1,
        addressLine2: model.addressLine2,
        addressLine3: model.addressLine3,
        city: model.city,
        postcode: model.postcode,
        phone: model.phone,
        email: model.email,
        updated: model.updated
    }
}

export const { resetSelectedSupplier, resetSuppliers } = supplierSlice.actions;
export default supplierSlice.reducer;
