import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import SupplierSidebar from "../../components/layout/supplier-sidebar";
import SupplierProducts from "./supplier-views/supplier-products";
import MapProducts from "./supplier-views/map-products";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ModalID } from "../../constants/modal-constants";
import { openModal } from "../../store/features/modal/modal-slice";
import { resetSuppliers } from "../../store/features/supplier/supplier-slice";
import UploadSupplierProducts from "./supplier-views/upload-supplier-product";

export default function SupplierView() {
  const supplier = useAppSelector((state) => state.supplier).selectedSupplier;
  const [contentSection, setContentSection] = useState<React.ReactNode>(null);
  const [contentTitle, setContentTitle] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (supplier?.id) {
      setContent(<SupplierProducts supplierId={supplier!.id} />, "Products");
    }
  }, [supplier?.id]);

  const setContent = (element: React.ReactNode, title: string) => {
    setContentSection(element);
    setContentTitle(title);
  };
  const handleUploadSuccess = () => {
    // After a successful upload, you can either refresh the products or navigate
    setContent(<SupplierProducts supplierId={supplier!.id} />, "Products");
  };

  return (
    <Container>
      <NavigationSection>
        <SupplierSidebar
          items={[
            {
              label: "Products",
              action: () =>
                setContent(
                  <SupplierProducts supplierId={supplier!.id} />,
                  "Products"
                ),
            },
            {
              label: "Add new product",
              action: () =>
                dispatch(
                  openModal({
                    id: ModalID.EditSupplierProduct,
                    data: { id: 0 },
                  })
                ),
            },
            {
              label: "Upload products",
              action: () =>
                setContent(
                  <UploadSupplierProducts
                    supplierId={supplier!.id}
                    handleUploadSuccess={handleUploadSuccess}
                  />,
                  "Upload Products"
                ),
            },
            {
              label: "Map products",
              action: () =>
                setContent(
                  <MapProducts supplierId={supplier!.id} />,
                  "Map products"
                ),
            },
            {
              label: "Update details",
              action: () =>
                dispatch(
                  openModal({
                    id: ModalID.EditSupplierModal,
                    data: { id: supplier?.id },
                  })
                ),
            },
            {
              label: "Contact supplier",
              action: () => console.log("hi"),
            },
            {
              label: "Delete supplier",
              action: () => console.log("hi"),
            },
          ]}
        />
      </NavigationSection>

      <ContentSection>
        <ContentTitle>{contentTitle}</ContentTitle>
        {contentSection}
      </ContentSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 10px;
  column-gap: 20px;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;
const ContentTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};
  padding: 10px;
`;
const NavigationSection = styled.div`
  min-width: 15%;
`;
const ContentSection = styled.div`
  display: flex;
  box-shadow: ${(props) => props.theme.application.boxShadow};
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding: 8px;
  background: ${(props) => props.theme.application.background};
  border-radius: 4px;
`;
