import React from 'react'
import styled from 'styled-components'

type SupplierListItem = {
    label: string,
    action: () => void;
}
interface SupplierSideBarProps {
    items: SupplierListItem[]
}

export default function SupplierSidebar({ items }: SupplierSideBarProps) {
    return (
        <Container>
            {items.map((x) => {
                return <ListItem key={x.label} onClick={x.action}> {x.label}</ListItem>
            })}
        </Container>
    )
}


const Container = styled.div`
    display:flex;
    flex-direction: column;
    box-shadow: ${props => props.theme.application.boxShadow}; 
      @media (max-width: 1600px) {
        flex-direction:row;
        margin-bottom:10px;
    }

background: ${props => props.theme.application.background}; 
border-radius:5px;
    text-align:center; 
`

const ListItem = styled.div`
padding:15px;
border-bottom:1px solid  ${props => props.theme.application.border};
cursor:pointer;

&:hover{
    background: ${(props) => props.theme.application.table.hover};
}

`

