import { ApplicationError } from "../../models/errors/application-error";
import IOrderService from "./order-service-interface";
import { ApplicationOrderList } from "../../models/order/application-order-list";
import { ApplicationOrder } from "../../models/order/application-order";
import { ApplicationOrderLineList } from "../../models/order/application-order-line-list";
import { ApplicationApprovalStatus } from "../../models/order/application-approval-status";
import { mapStringToEnum } from "../../helpers/enum-helper";
import client from "../../external/open-api";

export default class OrderService implements IOrderService {
    async create(basketId: number): Promise<ApplicationOrder> {
        const { data, error } = await client.POST("/v{version}/api/order", {
            params: {
                path: { version: "1" },
            },
            body: {
                basketId
            }
        });

        if (!data || error) {
            throw new ApplicationError("Api call failed creating order");
        }

        const response = await this.handleResponse(data, "Failed to create order");
        return this.formatOrder(response ?? {});
    }

 async getDelivered(branchId: number, currentPosition: number, fetchNext: number): Promise<ApplicationOrderList> {
        const { data, error } = await client.GET("/v{version}/api/order/branch/{branchId}/delivered", {
            params: {
                path: { version: "1", branchId },
                query: {
                    "Paging.CurrentPosition": currentPosition,
                    "Paging.FetchNext": fetchNext,
                    "Paging.FetchAll": false,
                },
            },
        });

        if (!data || error) {
            throw new ApplicationError("Api call failed fetching delivered orders");
        }

        const response = await this.handleResponse(data, "Failed to fetch delivered orders");

        const orders = response.orders?.map(this.formatOrder);

        return {
            orders: orders ?? [],
            count: response?.count || 0,
            currentPosition: response?.currentPosition || 0,
        };
    }
    async get(branchId: number, currentPosition: number, fetchNext: number): Promise<ApplicationOrderList> {
        const { data, error } = await client.GET("/v{version}/api/order/branch/{branchId}", {
            params: {
                path: { version: "1", branchId },  // Path parameter
                query: {
                    "Paging.CurrentPosition": currentPosition,
                    "Paging.FetchNext": fetchNext,
                    "Paging.FetchAll": false,
                },
            },
        });

        if (!data || error) {
            throw new ApplicationError("Api call failed fetching orders");
        }

        const response = await this.handleResponse(data, "Failed to fetch orders");

        const orders = response.orders?.map(this.formatOrder);

        return {
            orders: orders ?? [],
            count: response?.count || 0,
            currentPosition: response?.currentPosition || 0,
        };
    }

    async getById(orderId: number): Promise<ApplicationOrder> {
        const { data, error } = await client.GET("/v{version}/api/order/{orderId}", {
            params: {
                path: { version: "1", orderId },
            },
        });

        if (!data || error) {
            throw new ApplicationError("Api call failed fetching order");
        }

        const response = await this.handleResponse(data, "Failed to fetch order");
        return this.formatOrder(response ?? {});
    }

    async getOrderLines(orderId: number, currentPosition: number, fetchNext: number): Promise<ApplicationOrderLineList> {

        const { data, error } = await client.GET("/v{version}/api/order/{orderId}/orderlines", {
            params: {
                path: { version: "1", orderId },
                query: {
                    "Paging.CurrentPosition": currentPosition,
                    "Paging.FetchNext": fetchNext,
                    "Paging.FetchAll": false,
                },
            },
        });

        if (!data || error) {
            throw new ApplicationError("Api call failed fetching order");
        }

        const response = await this.handleResponse(data, "Failed to fetch order lines");

        const orderLines = response.orderLines?.map(d => ({
            id: d.id ?? 0,
            productId: d.productId ?? 0,
            productName: d.productName ?? "",
            productDescription: d.productDescription ?? "",
            productImage: d.productImage ?? "",
            productSize: d.productSize ?? "",
            quantity: d.quantity ?? 0,
            notes: d.notes ?? "",
            plu: d.plu ?? "",
            approvalStatus: mapStringToEnum(ApplicationApprovalStatus, d.approvalStatus ?? ""),
        }));

        return {
            orderLines: orderLines ?? [],
            count: response?.count || 0,
            currentPosition: response?.currentPosition || 0,
        };
    }

    private async handleResponse<T>(response: { success?: boolean, data?: T }, errorMessage: string): Promise<T> {
        if (!response.success) {
            throw new ApplicationError(errorMessage);
        }
        return response.data!;
    }

    private formatOrder(order: any): ApplicationOrder {
        return {
            id: order.id,
            lineCount: order.lineCount,
            displayName: `Order #${order.id}`,
            created: order.created
        };
    }
}
