import styled from "styled-components"
import { GoogleIcon, ICON_ID, ICON_SIZES } from "../icons/google-icon"
import { ApplicationDepartment } from "../../models/organisation/application-department"
import { SideBar } from "../../styles/shared/page"
import { ApplicationUser } from "../../models/authentication/application-user"
import { ApplicationRole } from "../../models/authentication/application-roles"
import { useNavigate } from "react-router-dom"

interface DepartmentSidebarProps {
    departments: ApplicationDepartment[]
    onDepartmentClick: (department: ApplicationDepartment) => void
    user: ApplicationUser
}

export const DepartmentSidebar = (properties: DepartmentSidebarProps) => {
    const navigate = useNavigate();

    return <SideBar>
        <Title>Departments</Title>
        <List>
            {properties.departments.map(department => {
                return <ListItem key={department.id} onClick={() => properties.onDepartmentClick(department)}>
                    {department.name}
                    <GoogleIcon size={ICON_SIZES.Medium} id={ICON_ID.ChevronRight} />
                </ListItem>
            })}
        </List>

        {properties.user.role == ApplicationRole.Owner && (
            <AdminList>
                <ListItem onClick={() => navigate("/admin")}>
                    Owner Portal
                    <GoogleIcon size={ICON_SIZES.Medium} isWarning id={ICON_ID.ChevronRight} />
                </ListItem>
            </AdminList>
        )}

    </SideBar>
}


const Title = styled.div`     
    padding:8px;
    font-weight:500;
    margin-bottom:10px;
    text-align:center;
    
`
const AdminList = styled.div`
 border-top: 1px solid   ${props => props.theme.application.border};  
   margin-top: auto;
   cursor:pointer;

   span{
   
   }
`
const List = styled.div`
 cursor:pointer;

`

const ListItem = styled.div`    
 display:flex;
 flex-direction:row;
 justify-content: space-between;    
 padding: 14px 10px;

 border-bottom:1px solid  ${props => props.theme.application.border};   
 align-items: center; 

 &:hover
 {
    background:  ${props => props.theme.application.hover};  
 }
`