
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import TableView, { ColumnConfig } from '../../../components/controls/table-view';
import { ProductContainer, ProductImage, ProductNameContainer, ProductTitle } from '../../../styles/shared/product';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../../../components/icons/google-icon';
import Image from '../../../components/controls/image';
import { fetchSupplierProducts, mapProduct, resetSearchProducts, resetSupplierProducts, resetUnmappedSelectedProduct, searchSupplierProducts, setMapping, setUnmappedSelectedProduct } from '../../../store/features/supplier/supplier-product-slice';
import { AsyncThunkAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { ApplicationSupplierProductsList } from '../../../models/supplier/application-supplier-product-list';
import { RootState } from '../../../store/store';
import Button from '../../../components/controls/button';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { SmallTextContainer, TextContainer } from '../../../styles/shared/table';
import { ApplicationProductDetail } from '../../../models/product/application-product-detail';
import { ApplicationSupplierProductHeader } from '../../../models/supplier/application-supplier-product-header';
import TextInput from '../../../components/controls/text-input';
import ProductList from '../../../components/controls/product-list';
import { ApplicationProductList } from '../../../models/product/application-product-list';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import { showSuccess } from '../../../components/notification/toastr-actions';

interface MapProductsProps {
    supplierId: number
}
export default function MapProducts({ supplierId }: MapProductsProps) {
    const products = useAppSelector(state => state.supplierProducts);
    const [searchText, setSearchText] = useState("");
    const [productLoading, setProductLoading] = useState(false);
    const loadingRowRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const columnConfig: { [key: string]: ColumnConfig } = {
        id: {
            hidden: true,
        },
        productId: {
            hidden: true,
        },
        supplierId: {
            hidden: true,
        },
        description: {
            hidden: true,
        },
        image: {
            hidden: true,
        },
        newPrice: {
            hidden: true,
        },
        editPrice: {
            hidden: true,
        },
        price: {
            hidden: true
        },
        updated: {
            hidden: true
        },
        active: {
            hidden: true
        },
        plu: {
            hidden: true,
        },
        name: {
            name: "Product",
            order: 1,
            colspan: 2,
            render: (value: string, row: any) => (
                <ProductContainer>
                    <ProductImage>
                        {row.productId ?
                            <Image image={row.image}></Image>
                            : <GoogleIcon tooltip='Not mapped' id={ICON_ID.QuestionMark} isWarning size={ICON_SIZES.Large} />
                        }

                    </ProductImage>
                    <ProductNameContainer>
                        <ProductTitle>{value}</ProductTitle>
                        <SmallTextContainer> Plu: {row.plu}</SmallTextContainer>
                    </ProductNameContainer>
                </ProductContainer>
            )
        },
        size: {
            render: (value: string) => (
                <TextContainer>{value}</TextContainer>
            )
        },

    }

    const fetchSearchData = () => {
        dispatch(searchSupplierProducts({ supplierId: supplierId, searchText: searchText, fetchAll: false }))
    }

    useEffect(() => {
        if (searchText.length > 2 || searchText == "") {
            dispatch(resetSearchProducts())
            fetchSearchData();
        }
    }, [searchText]);

    useEffect(() => {
        fetchMoreData();

        return () => {
            dispatch(resetSupplierProducts())
            dispatch(resetUnmappedSelectedProduct())
        }
    }, [])


    useInfiniteScroll({
        dataLength: products.searchProducts?.currentPosition ?? 0,
        totalCount: products.searchProducts?.count ?? 0,
        fetchMoreData: fetchSearchData,
        setLoading: setProductLoading,
        loading: productLoading,
        loadingRowRef
    });


    const fetchMoreData = () => {
        dispatch(fetchSupplierProducts(supplierId));
    }

    const renderActionButton = (row: any) => (
        <Button
            variant="text"
            icon={ICON_ID.ChevronRight}
            id={`btnSelect${row.name}`}
            label=''
            isSecondary={true}
            fullWidth={false}
            onClick={() => onSelected(row)} />
    );

    const onSelected = async (row: any) => {
        dispatch(setUnmappedSelectedProduct(row as ApplicationSupplierProductHeader))
        setSearchText("");
        dispatch(resetSearchProducts())
        fetchSearchData();
    }

    const onMap = async (id: number, _: number, productName: string) => {
        await dispatch(mapProduct(id));
        dispatch(setMapping(id));
        dispatch(showSuccess(`Supplier product ${products.selectedUnmappedProduct?.name} mapped to ${productName}`))
    }

    return (
        <Container>

            <SmallTable>
                <TableView
                    id="tblProducts"
                    emptyText="No products"
                    fetchMoreData={fetchMoreData}
                    onRowClick={onSelected}
                    totalCount={products.products?.count ?? 0}
                    data={products?.products?.products ?? []}
                    columnConfig={columnConfig}
                    actionButton={renderActionButton}
                    preLoadedData={true}
                    showHeaders={true}
                />
            </SmallTable>
            <Content>
                {products.selectedUnmappedProduct && (
                    <MappingContainer>
                        <MappingTitle>Map {products.selectedUnmappedProduct.name}</MappingTitle>
                        <MappingSearch>
                            <TextInput id="txtSearchProducts" label="Find product" value={searchText} onChange={(event) => setSearchText(event.target.value)}></TextInput>
                        </MappingSearch>
                        <MappingProducts>
                            <ProductList
                                loadingRowRef={loadingRowRef}
                                products={products.searchProducts ?? ({} as ApplicationProductList)}
                                selectedProductIds={[
                                    products.selectedUnmappedProduct?.productId ?? -99
                                ]}
                                categoryId={-99}
                                onClick={onMap}
                            />

                        </MappingProducts>
                    </MappingContainer>
                )}


            </Content>
        </Container>
    )
}


const Container = styled.div`
     flex: 0 0 70vh;      
      min-height: 0; 
    display:flex;
    padding: 0px 0px 0px 25px;
`

const SmallTable = styled.div`
width:30%;
height:calc(100% - 30px);
`

const Content = styled.div`

width:70%;
height:100%;
`

const MappingContainer = styled.div`
display:flex;
flex-direction:column;
height: 82%;
padding: 50px 50px 0px 50px;
`

const MappingTitle = styled.div`
    font-size:1.6rem;
    font-weight:600;
`

const MappingSearch = styled.div`

`

const MappingProducts = styled.div`
    display:flex;
    font-size:0.8rem;


    overflow-y:scroll;
        &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: ${props => props.theme.application.product.background};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.application.scrollBar};
        border: 2px solid ${props => props.theme.application.scrollBar};
        border-radius: 4px;
    }
`