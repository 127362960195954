import { Dispatch } from "@reduxjs/toolkit";
import { generateRandomId } from "../../helpers/id-helper";
import {
  removeNotification,
  addNotification,
} from "../../store/features/notification/toastr-slice";
import { AppDispatch } from "../../store/store";

export const showSuccess = (message: string) => (dispatch: Dispatch) => {
  const id = generateRandomId();
  dispatch(addNotification({ id, type: "success", message }));
  setTimeout(() => {
    dispatch(removeNotification(id));
  }, 3000);
};

export const showError = (message: string) => (dispatch: Dispatch) => {
  const id = generateRandomId();
  dispatch(addNotification({ id, type: "error", message }));
  setTimeout(() => {
    dispatch(removeNotification(id));
  }, 3000);
};
